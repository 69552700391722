import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/casestudies.scss';

function CaseStudiesCityConcierge(){
    PageTitle('Case Study: Tokeet & The City Concierge');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('casestudies-layout')
        return () => {
          document.body.classList.remove('casestudies-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner city_concierge">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6 banner-caption">
                        <h4>CASE STUDY</h4>
                        <h1>
                            The City Concierge’s<br />
                            Rapid Expansion
                        </h1>
                    </div>
                    <div className="col-md-6 banner-holder">
                        <img src={require('../../assets/images/case-studies/city-concierge-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="overview-holder">
            <div className="container">
                <div className="overview-dark-box">
                    <div className="row">
                        <div className="col-md-6 box-left">
                            <p>
                                “We started as a small rental management company offering bespoke solutions to a small number 
                                of rental properties, but after quick growth, we needed to find a rental management system to 
                                handle hundreds of rentals and thousands of reservations.”
                            </p>
                            <div className="auth-flx-box">
                                <img src={require('../../assets/images/case-studies/paulo_bastas.png')} alt="" />
                                <div>
                                    <h5>Paulo Bastas</h5>
                                    <p>Company Co-founder</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 box-right">
                            <h4>COMPANY</h4>
                            <img src={require('../../assets/images/case-studies/city-concierge-logo.png')} alt="" />
                            <p>
                                We help home owners that want to rent their beautiful houses but don’t have the possibility 
                                to receive their guests. Renting a house is hard work: you have to clean the house, meet your 
                                guests when they arrive and assure they have a good stay.
                            </p>
                            <a href="http://www.thecityconcierge.com" target="_blank" rel="noreferrer">www.thecityconcierge.com</a>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="overview-content-box">
                            <h4>OVERVIEW</h4>
                            <h1>
                                We all know just how much work managing a single property 
                                can be – but what about 1000?
                            </h1>
                            <div className="row">
                                <div className="col-md-6 box-left">
                                    <p>
                                        <strong>The City Concierge</strong> is fast approaching that landmark after just 4 years of operation. 
                                        The City Concierge is a full-service management agency operating across six cities in two 
                                        countries – with further expansion on the horizon.
                                    </p>
                                </div>
                                <div className="col-md-6 box-right">
                                    <p>
                                        So how did they manage to reach such lofty heights? 
                                        As company co-founder Paulo <strong>Bastos</strong> describes it, the transition was sudden:
                                    </p>
                                </div>
                            </div>
                            <div className="img-holder">
                                <img src={require('../../assets/images/case-studies/overview.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </section>
        <section className="performance-holder">
            <div className="container">
                <div className="title-holder">
                    <h4>PERFORMANCE</h4>
                    <h1>Tokeet’s Contributions</h1>
                </div>
                <div className="row performance-content-box">
                    <div className="col-md-6 content-box-l">
                        <img src={require('../../assets/images/case-studies/city-concierge-lg.png')} className="img-fluid" alt="" />
                        <p>
                            Just a couple years after implementing Tokeet, <strong>The City Concierge</strong> is a significantly larger company. 
                            <br /><br />
                            Their staff size grew by <strong>200%</strong> (not including contracted employees), while the total number of active listings increased by <strong>270%!</strong> The decision to implement a channel management system couldn’t have come at a more critical time. 
                            <br /><br />
                            While growth is an important marker in a company’s value, it doesn’t necessarily translate to more profits. That’s why it’s important to note that, when examining the employee to listing ratio, the staff’s operating efficiency has also improved by <strong>25%</strong>
                        </p>
                    </div>
                    <div className="col-md-6 content-box-r">
                        <ul>
                            <li>Managed all <strong>client properties</strong> across multiple listing channels. </li>
                            <li>Improved <strong>guest communication</strong> response times.</li>
                            <li>Increased efficiency with <strong>automated operations.</strong></li>
                            <li>Provided clients with personalized data <strong>reports on-demand</strong> (previously done manually).</li>
                        </ul>
                        <img src={require('../../assets/images/case-studies/tokeet-contribution.png')} className="img-fluid" alt="" />
                        <p>
                            <strong>The City Concierge</strong> reports that each of their needs was met, and the software exceeded expectations 
                            by providing a number of secondary features that helped increase efficiency and customer experience.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="moving-forward">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Moving Froward</h1>
                        <p>
                            With a <strong>reliable management system</strong> in place and a proven ability to thrive in new locations, The City Concierge is expected to stand within the top three European rental management companies in just a few years time. 
                            <br /><br />
                            These aren’t baseless expectations, as the company is a process of continuous expansion. The plan, according to Bastos, is for the company to be present in twenty European cities by 2022. He estimates their active listings will increase to <strong>over 4000</strong> by that time. 
                            <br /><br />
                            When asked where <strong>Tokeet</strong> may fit into these plans, Bastos had this to say:
                        </p>
                    </div>
                    <div className="col-md-6 img-holder">
                        <img src={require('../../assets/images/case-studies/moving-forward.png')} className="img-fluid" alt="" />
                    </div>
                </div>
                <div className="learn-more-about-business">
                    <div className="col-lg-8">
                        We’d like to thank co-founder Paulo Bastos for taking the time to speak to us about The City Concierge!                    
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default CaseStudiesCityConcierge;