import { React, useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import ClockLoader from "react-spinners/ClockLoader";

import Navbar from "./app/components/Navbar";
import Footer from "./app/components/Footer";
import Home from "./app/pages/Home";
import Pricing from "./app/pages/Pricing";
import About from "./app/pages/About";
import Integrations from "./app/pages/Integrations";
import Automation from "./app/pages/Automation";
import ChannelManager from "./app/pages/ChannelManager";
import DirectBookings from "./app/pages/DirectBookings";
import DynamicPricing from "./app/pages/DynamicPricing";
import InvoicePayments from "./app/pages/InvoicePayments";
import OwnerManager from "./app/pages/OwnerManager";
import PremiumServices from "./app/pages/PremiumServices";
import StaffManagement from "./app/pages/StaffManagement";
import PrivacyPolicy from "./app/pages/Privacy";
import TermsService from "./app/pages/Terms";
import Affiliate from "./app/pages/Affiliate";
import AffiliateFaq from "./app/pages/AffiliateFaq";
import TokeetReviews from "./app/pages/TokeetReviews";
import CaseStudies from "./app/pages/CaseStudies";
import CaseStudiesBnbeyond from "./app/pages/CaseStudiesBnbeyond";
import CaseStudiesCityConcierge from "./app/pages/CaseStudiesCityConcierge";
import BecomeApartner from "./app/pages/BecomeApartner";
import BecomeApartnerFormSuccess from "./app/pages/BecomeApartnerFormSuccess";

function App() {
  const [isFetching, setIsFetching] = useState(true); 
  useEffect(() => {
      setTimeout(function () {
      setIsFetching(false); 
      }, 2000);
  }, []);

  if (isFetching) {
    return (        
      <div className="loading-screen">
          <ClockLoader color="#1AA99D" size={50} />
      </div>
    );
  }
  return (   
    <BrowserRouter>         
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/integrations" element={<Integrations />} />
        <Route path="/solutions/automation" element={<Automation />} />
        <Route path="/solutions/channel-manager" element={<ChannelManager />} />
        <Route path="/solutions/direct-bookings" element={<DirectBookings />} />
        <Route path="/solutions/dynamic-pricing" element={<DynamicPricing />} />
        <Route path="/solutions/invoice-payments" element={<InvoicePayments />} />
        <Route path="/solutions/owner-manager" element={<OwnerManager />} />
        <Route path="/solutions/premium-services" element={<PremiumServices />} />
        <Route path="/solutions/staff-management" element={<StaffManagement />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy.html" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsService />} />
        <Route path="/terms.html" element={<TermsService />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/affiliate/faq" element={<AffiliateFaq />} />
        <Route path="/tokeet-reviews" element={<TokeetReviews />} />
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/case-studies/bnbeyond" element={<CaseStudiesBnbeyond />} />
        <Route path="/case-studies/city-concierge" element={<CaseStudiesCityConcierge />} />
        <Route path="/become-a-partner" element={<BecomeApartner />} />
        <Route path="/become-a-partner/success" element={<BecomeApartnerFormSuccess />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
