import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/privacy.scss';

function PrivacyPolicy(){
    PageTitle('Tokeet | Privacy Policy');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('privacy-terms-layout')
        return () => {
          document.body.classList.remove('privacy-terms-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h1>Privacy Policy</h1>
                    </div>
                    <div className="col-auto">
                        <h4>Modified: May 2, 2018</h4>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-holder">
            <div className="container">
                <div className="container-box">
                    <div className="content-box">
                        <p>
                            At Tokeet, we like to keep things simple. So we offer you this abbreviation of our Privacy Policy.
                            <br/><br/>
                            We do not reveal, sell, give, or otherwise share your personal information, or the information you enter into our Service, with anyone - unless a judge asks us to hand it over in which case we give it to them.  We take measures to secure yours' and your customers' data, and give you additional tools to allow you to secure your data.
                            <br/><br/>
                            Any now the more lengthy, legal version....
                        </p>
                        <p>
                            Tokeet collects information about people who use our Service and visit our websites. Such information may contain personal data about you including your address, phone numbers, credit card numbers etc. We are not allowed to disclose such personal information without your written permission.
                            <br/><br/>
                            Some information collected from you and about you is used within the context of providing the Service. The information we collect is not shared with or sold to others except within the context of providing you the Service, and which your use of the Service is deemed to provide to us a valid consent to disclose the following:
                        </p>
                        <ol>
                        <li>
                            In order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Tokeet's terms of use, or as otherwise required by law.
                        </li>
                        <li>
                            Tokeet does not rent, sell, or share personal information about you with other companies except to provide products or services you've requested or when we have your permission.
                        </li>
                        <li>
                            We will transfer information about you if the Company or Tokeet is acquired by or merged with another company. In this event, Tokeet will notify you by email or by putting a prominent notice on the Tokeet web site before information about you is transferred and becomes subject to a different privacy policy.
                        </li>
                        </ol>

                        <h2>What we do with your Information</h2>
                        <ol>
                        <li>
                            When you register for Tokeet we ask for your name, company name, email address. If you want to keep your account after the trial period you will need to provide your credit card information.
                        </li>
                        <li>
                            Tokeet uses the information we collect for the following general purposes: products and services provision, billing, identification and authentication, services improvement, contact, and research.
                        </li>
                        <li>
                            As part of the Service provided by Tokeet, you will obtain the email address and/or address of your customers (guests). By entering into our User Agreement, you agree that, with respect to other users' personal data that you obtain through Tokeet or through a Tokeet-related communication or Tokeet-facilitated transaction, Tokeet hereby grants to you a license to use such information only for Tokeet-related communications that are not unsolicited commercial messages. Tokeet does not tolerate spam. Therefore, without limiting the foregoing, you are not licensed to add the name of someone who has purchased an item from you, to your mail list (email or physical mail) without their express consent.
                        </li>
                        <li>
                            Personal data contained in your data, and in data of your customers (guests), is subject to Data Protection Legislation: (a) the GDPR; and/or (b) the Federal Data Protection Act of 19 June 1992 (Switzerland).
                        </li>
                        </ol>

                        <h2>Processing of Data</h2>
                        <ol>
                        <li>
                            Tokeet is a processor of your and your customers' Personal Data under the Data Protection Legislation, with the exception of Tokeet website builder and widgets, where Tokeet is a controller.
                        </li>
                        <li>
                            You are a controller or processor, as applicable, of your and your customers' Personal Data under Data Protection Legislation.
                        </li>
                        <li>
                            Both Tokeet and you acknowledge and agree to comply with the obligations applicable under the Data Protection Legislation with respect to the processing of your and your customers' Personal Data.
                        </li>
                        <li>
                            You instruct Tokeet to process Personal Data only in accordance with applicable law: (a) to provide the Service defined in the Tokeet Terms of Service; (b) as further specified via your use of the Service.
                        </li>
                        </ol>

                        <h2>Data Security</h2>
                        <ol>
                        <li>
                            <u>Tokeet's Security Measures</u>. Tokeet will implement and maintain technical and organizational measures to protect your Personal Data against accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access. The Security Measures include measures to encrypt personal data; to help ensure ongoing confidentiality, integrity, availability and resilience of Tokeet's systems and services; to help restore timely access to personal data following an incident; and for regular testing of effectiveness. Tokeet may update or modify the Security Measures from time to time provided that such updates and modifications do not result in the degradation of the overall security of the Service.
                        </li>
                        <li>
                            <u>Security Compliance by Tokeet Staff</u>. Tokeet will take appropriate steps to ensure compliance with the Security Measures by its employees, contractors and subprocessors to the extent applicable to their scope of performance, including ensuring that all persons authorized to process your Personal Data have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality.
                        </li>
                        <li>
                            <u>Additional Security Controls</u>. In addition to the Security Measures, Tokeet will make the Additional Security Controls available to: (a) allow You to take steps to secure your Personal Data; and (b) provide You with information about securing, accessing and using your Personal Data.
                        </li>
                        <li>
                            <u>Data Incidents</u>. Data incidents means a breach of Tokeet's security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, your Personal Data on systems managed by or otherwise controlled by Tokeet. Data Incidents will not include unsuccessful attempts or activities that do not compromise the security of your Personal Data, including unsuccessful log-in attempts, pings, port scans, denial of service attacks, and other network attacks on firewalls or networked systems. <br /><br />

                                4.1 If Tokeet becomes aware of a Data Incident, Tokeet will: (a) notify You of the Data Incident promptly and without undue delay after becoming aware of the Data Incident; and (b) promptly take reasonable steps to minimize harm and secure your Personal Data.
                                <br /><br />
                                4.2 Notification(s) of any Data Incident(s) will be delivered to the Account Info Email Address. You are solely responsible for ensuring that the Account Info Email Address is current and valid.
                                <br /><br />
                                4.3 Tokeet will not assess the contents of your Personal Data to identify information subject to any specific legal requirements. You are solely responsible for complying with incident notification laws applicable to You and fulfilling any third party notification obligations related to any Data Incident(s).
                                <br /><br />
                                4.4 Tokeet's notification of or response to a Data Incident under this Section 4 (Data Incidents) will not be construed as an acknowledgement by Tokeet of any fault or liability with respect to the Data Incident.
                        </li>
                        <li>
                            <u>Tokeet's Security Assistance</u>. You agree that Tokeet will (taking into account the nature of the processing of your Personal Data and the information available to Tokeet) assist You in ensuring compliance with any of Your obligations in respect of security of personal data and personal data breaches, including if applicable Your obligations pursuant to Articles 32 to 34 (inclusive) of the GDPR, by:<br /><br />
                                (a) implementing and maintaining Tokeet's Security Measures;<br /><br />
                                (b) making the Additional Security Controls available to You;<br /><br />
                                (c) notifying You of Data Incidents promptly and without undue delay.
                        </li>
                        <li>
                            <u>Your Security Responsibilities</u>. You agree that, without prejudice to Tokeet's obligations:<br /><br />
                                (a) You are solely responsible for its use of the Service, including:
                                    <ul>
                                        <li>
                                            (i) making appropriate use of the Service and the Additional Security Controls to ensure a level of security appropriate to the risk in respect of the your Personal Data;
                                        </li>
                                        <li>
                                        (ii) securing the account authentication credentials, systems and devices You use to access the Service;
                                        </li>
                                        <li>
                                        (iii) backing up its your Personal Data; and
                                        </li>
                                    </ul>
                                (b) Tokeet has no obligation to protect your Personal Data that You elect to store or transfer outside of Tokeet's systems, or to protect your Personal Data by implementing or maintaining Additional Security Controls except to the extent You opted to use them.
                        </li>
                        <li>
                            <u>Your Security Assessment</u>.<br /><br />
                                (a) You are solely responsible for evaluating for yourself whether the Service, the Security Measures, the Additional Security Controls and Tokeet's commitments under this Section (Data Security) will meet Your needs, including with respect to any security obligations of You under the Data Protection Legislation.
                                <br /><br />
                                (b) You acknowledge and agree that (taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of the processing of your Personal Data as well as the risks to individuals) the Security Measures implemented and maintained by Tokeet provide a level of security appropriate to the risk in respect of the your Personal Data.

                        </li>
                        </ol>

                        <h2>Disclosure</h2>
                        <p>
                            Tokeet may disclose your data under special circumstances, such as to comply with court orders requiring us to do so.
                        </p>

                        <h2>Client Data Storage</h2>
                        <p>
                            Tokeet owns the data storage, databases and all rights to the Tokeet application however we make no claim to the rights of your data. You retain all rights to your data and we will never contact your clients directly, or use your data for our own business advantage or to compete with you or market to your clients.
                        </p>

                        <h2>Cookies</h2>
                        <ol>
                        <li>
                            A cookie is a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site's computers and stored on your computer's hard drive.
                        </li>
                        <li>
                            Tokeet uses cookies to record current session information, our cookies generally last two weeks. You are required to log-in to your Tokeet site after a certain period of time has elapsed to protect you against others accidentally accessing the content of your account.
                        </li>
                        </ol>
                        <h2>Changes to this Privacy Policy</h2>
                        <p>
                            We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, Tokeet will inform You at least 30 days (or such shorter period as may be required to comply with applicable law, applicable regulation, a court order or guidance issued by a governmental regulator or agency) before the change will take effect by either: (a) sending an email to the Account Info Email Address; or (b) alerting You of the changes at <a href="https://changes.tokeet.com/">https://changes.tokeet.com</a>.
                        </p>
                    </div>
                </div>                
            </div>
        </section>
        </>
    );
}

export default PrivacyPolicy;