import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function DynamicPricing(){
    PageTitle('Tokeet | Dynamic Pricing for Short-Term Rental Managers');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 banner-caption">
                            <h1>
                                Dynamic Pricing
                            </h1>
                            <p>
                                Increase your rental revenue by <br className="d-none d-lg-block" />
                                up to 30% with the Perfect Price, Everyday.
                            </p>
                            <p className="btn-holder">
                                <a href="https://register.rategenie.io/account" className="btn btn-start-trial">
                                    Start Free Trial
                                </a>
                                <a href="https://youtu.be/amJKHzstlhI" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                            <img src={require('../../assets/images/solutions/dynamic-pricing-parter.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/dynamic-pricing-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="temp-content-holder">
                <div className="container">
                    <div className="dark-box-top">
                        <div className="title-holder">            
                            <h4>METRICS AND MARKET</h4>            
                            <h1>
                                Use metrics and market data to beat <br className="d-none d-lg-block" />
                                the competition.
                            </h1>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/dynamic_rate.png')} alt="" />
                                </div>
                                <h4>
                                    Dynamic Rate
                                </h4>
                                <p>
                                    See your nightly prices change in your calendar as your rate strategies and occupancy change. Automatically update your rates daily with the perfect price across multiple channels. Our simple to use calendars and charts makes it all easy.
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/perfect_price.png')} alt="" />
                                </div>
                                <h4>Perfect Price</h4>
                                <p>
                                    Fine tune your strategies for each rental in your inventory and across your channels. Rategenie's flexible algorithms and pricing controls are the best on the market and will also help you maximize your profits. Our wide range of rules and customizations helps you create pricing that's perfect for you.
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/sync_rate.png')} alt="" />
                                </div>
                                <h4>Sync Rate</h4>
                                <p>
                                    Update all connected channels at least once per day with your perfect price. Say goodbye to stale prices and low revenue bookings. An intuitive dashboard and rich market data mean you have all the information you need to make the important decisions. Rules also allow you to customize your rates depending on the channel.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Dynamic Pricing <br className="d-none d-lg-block" />
                                    Strategies
                                </h1>
                                <p>
                                    Create the perfect rate strategy for your rentals and market.
                                </p>
                                <div className="accordion" id="accordionDynamicPricing">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                The smartest way to price your short-term rental
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionDynamicPricing">
                                            <div className="accordion-body">
                                                <p>
                                                    Generate the best price for your rental without any guesswork. We know that one size doesn’t fit all. That’s why we made Rategenie flexible enough to adapt to any market, anywhere in the world. Whether you’re in a large city or on a small remote island, Rategenie will help you build a pricing strategy that works for your market. You can base your strategies on historical trends, 
                                                    your rental’s occupancy, a fixed price, or use our Genie Strategy to get started.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                Powerful Insights
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionDynamicPricing">
                                            <div className="accordion-body">
                                                <p>
                                                    Get the most from your time and money by letting our algorithms do the work, you can even take advantage of metrics and view market data for your rentals. We've partnered with the leading data and event intelligence companies to build a sophisticated rates engine that is both easy to use and delivers powerful results. Rategenie is the result of years of experience and 
                                                    knowledge of the short-term rental industry, and research into rental pricing and profitability.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                Dynamic Pricing
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionDynamicPricing">
                                            <div className="accordion-body">
                                                <p>
                                                    Stay on top of changing variables to get the best nightly price without lifting a finger. Rategenie's pricing is truly dynamic and changes instantly when the conditions and variables in your strategy change. More importantly, we'll update your 
                                                    rates on every channel after each change - at least once per day, but typically much more frequently.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href="https://register.rategenie.io/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                    <span>Try for Free</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/rate_strategy.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/powerful_rules.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Powerful Rules & <br className="d-none d-lg-block" />
                                    Customizations
                                </h1>
                                <p>
                                    You’re in full control with Rategenie dynamic pricing.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Events & Holiday Predictions</h4>
                                            <p>
                                                Capitalize on events in your rental location. 
                                                Rategenie tracks the big days on your calendar and can automatically adjust your rates to suit.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Minimum Stay Adjustments</h4>
                                            <p>
                                                Change your minimum nights stay requirement as you like based on many different factors.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Price Customization</h4>
                                            <p>
                                                Adjust your price depending on the channel, advanced bookings, day of week, gap nights & more.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <a href="https://register.rategenie.io/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                    <span>Try for Free</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    The Perfect Price On <br className="d-none d-lg-block" />
                                    Every Channel
                                </h1>
                                <p>
                                    Send customized rates to an expansive list of channel connections on the market.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>API Integrations</h4>
                                            <p>
                                                Rategenie has official API integrations with the most popular vacation rental channels.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Competitive Price</h4>
                                            <p>
                                                Ensure the most competitive price on every channel at anytime during any day with Rategenie’s continuous push technology.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Stop waiting for your rates</h4>
                                            <p>
                                                Stop waiting for your rates to change. Rategenie updates your rates as often as needed so your prices are always perfect.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <a href="https://register.rategenie.io/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                    <span>Try for Free</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/perfect_price_on_evy_channel.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/market_data.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Use metrics and market data <br className="d-none d-lg-block" />
                                    to beat the competition
                                </h1>
                                <p>
                                    View all of the data you need to make the right decisions.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>No Guesswork</h4>
                                            <p>
                                                Take the guesswork out of generating your rates.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Gain a deeper</h4>
                                            <p>
                                                With Rategenie you will gain a deeper understanding of your local market.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Market Metrics</h4>
                                            <p>
                                                Intuitive graphs show detailed rental and market metrics.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DynamicPricing;