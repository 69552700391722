import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function ChannelManager(){
    PageTitle('Tokeet | Advanced Airbnb & Channel Manager Software');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner channel-manager-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6 banner-caption">
                        <h1>
                            Channel Management
                        </h1>
                        <p>
                            Our effortless environment gives you complete control so 
                            you can easily keep track of your rate and availability syncs to channels.
                        </p>
                        <p className="btn-holder">
                            <a href="https://register.tokeet.com/" className="btn btn-start-trial">
                                Start Free Trial
                            </a>
                            <a href="https://www.youtube.com/embed/XJV5D-HyB_g" data-lity className="btn watch-video-btn">
                                <span>Watch Video</span>
                                <i className="bi bi-play-circle-fill"></i>
                            </a>
                        </p>
                        <img src={require('../../assets/images/solutions/channel-partners.png')} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6 banner-holder order-first order-md-last">
                        <img src={require('../../assets/images/channel-manager-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="temp-content-holder">
            <div className="container">
                <div className="dark-box-top">
                    <div className="title-holder">            
                        <h4>CONTROL & VISIBILITY</h4>            
                        <h1>
                            Tokeet has re-imagined channel <br className="d-none d-lg-block" />
                            management so you have full control <br className="d-none d-lg-block" />
                            and visibility.
                        </h1>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/easy_to_use.png')} alt="" />
                            </div>
                            <h4>Easy To Use</h4>
                            <p>
                                Setup is fast and we provide lots of helpful instructions to make it easy. 
                                Designed to be the most advanced and user-friendly channel manager on the market. 
                                Tokeet has API integrations with the most important channels like Airbnb, Booking.com, 
                                and Expedia so you can utilize all the most advanced features of each. 
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/automatic_sync.png')} alt="" />
                            </div>
                            <h4>Automatic & Instant Synchronization</h4>
                            <p>
                                Sleep easy knowing Tokeet is in full control and all your channels are up-to-date. 
                                Synchronization with the top channels is automatic and instant. 
                                Instant booking notification means all channels are updated within seconds of a booking. 
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/nothing_hidden.png')} alt="" />
                            </div>
                            <h4>Nothing is hidden</h4>
                            <p>
                                Our activity logs allow you to trace every pricing change and know exactly when each update was made. 
                                Tokeet provides complete visibility into exactly what’s happening. 
                                You can see when your channels were synced and what data was sent to each channel. 
                                There is no black box or hidden communications. 
                            </p>
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                            <h1>
                                Much More Than Just <br className="d-none d-lg-block" />
                                Airbnb Management Software
                            </h1>
                            <p>
                                There’s no shortage of dedicated Airbnb management software options out there, but none of them are true channel managers.
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Flexibility</h4>
                                        <p>
                                            Tokeet puts you ahead of the game whether you need to manage your own rental or multiple home owners’ properties. 
                                            When we say flexibility, we mean our software can do it all.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Flexible Pricing Platform</h4>
                                        <p>
                                            Only pay for what you need, with no booking fees with our pricing structure.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Innovative Add-on Apps</h4>
                                        <p>
                                            Automate your communication, maximize profits & direct bookings, 
                                            dynamically generate rates and more with our innovative add-on apps.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/much_more_than_airbnb.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 img-holder order-last order-md-first">
                            <img src={require('../../assets/images/solutions/stop_overbookings.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 content">
                            <h1>
                                Stop Overbookings On <br className="d-none d-lg-block" /> 
                                All Channels
                            </h1>
                            <p>
                                Enjoy peace of mind knowing that you never risk overbooking your most important channels. Tokeet synchronizes availability on all your channels automatically 
                                with instant API synchronization with Airbnb, Booking.com, Agoda, Homeaway, and Expedia.
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/sync_calendars.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Sync calendars with iCals</h4>
                                        <p>
                                        We also synchronize calendars with iCals, Holiday Lettings, and many more.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/instant_notification.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Instant Notification</h4>
                                        <p>
                                            Receive instant booking notifications from the top channels and automatically import booking details like price and dates.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/stop_waiting.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Full Transparency</h4>
                                        <p>
                                            View activity logs showing the prices and availability that are sent to the channels.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                            <h1>
                                Complete & Seamless <br className="d-none d-lg-block" />
                                Integration
                            </h1>
                            <p>
                                Stay in the know with notifications and 
                                enjoy the ease of having all of your information and features in one place. 
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/save_billing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Save Billing Details</h4>
                                        <p>
                                        Save guest billing details automatically using your connected payment gateway.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/pre-approve.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Pre-approve</h4>
                                        <p>
                                        Pre-approve inquires on Airbnb and message guests directly from Tokeet.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/get_notified.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Get Notified</h4>
                                        <p>
                                            Get notified whenever a new booking is received and automatically send emails, contracts, and other information.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/seamless_integration.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default ChannelManager;