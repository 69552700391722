import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/about.scss';

function About(){
    PageTitle('Tokeet | Your Short-Term Rental Management Software Team');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('about-page')
        return () => {
          document.body.classList.remove('about-page')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8 banner-holder">
                        <img src={require('../../assets/images/about-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="about-content-holder">
            <div className="container">
                <div className="dark-box-top">
                    <div className="title-holder">            
                        <h4>ONE RESERVATION AT A TIME</h4>
                    </div>
                    <div className="row">
                        <div className="col-md-7 box-lhs">
                            <h1>Simplifying Short-term Rental Management. One Reservation At A Time.</h1>
                            <p>
                                Our mission is to develop software solutions that make short-term rental management easy and fun, without compromising on enterprise-level features. We’re passionate about this goal, and as vacation rental owners ourselves, believe in using our own software.
                            </p>
                        </div>
                        <div className="col-md-5 box-rhs">
                            <p>
                                While looking online for a simple solution to help manage his vacation rentals, our founder realized that nothing existed that truly fit his desired needs. Most of the established solutions were either poor adaptations of older hotel and property rental systems, or were newer products that didn’t fully reflect all of the various aspects of vacation rental management. Most importantly, they were difficult to use. 
                                <br /><br />
                                After frustratingly trying to use a few of the available solutions he gave up and decided that the market needs a new and innovative approach. He assembled a team and within a few months had launched the web version of Tokeet.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="content-box">
                    <div className="title-flx-box">
                        <h1>
                            Vested In Our <br />
                            User’s Success
                        </h1>
                        <p>
                            We believe that by empowering our users to do<br />
                            more they will succeed and so will we.
                        </p>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-6 content">
                            <p>
                                Tokeet understands that our users trust us to help them operate their businesses, whether it’s a one man show or a large corporation. We take that trust seriously and go the extra mile to ensure that our software is available and ready to use whenever you need it. At Tokeet your success is our success.
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/workflow-platform.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Workflow platform</h4>
                                        <p>
                                            Tokeet is a complete rental management workflow platform. It’s designed for companies and individuals alike.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/host-of-add-ons.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Host of add-ons</h4>
                                        <p>
                                            The base module is filled with everything you need and nothing you don’t. If you want added features we also offer a host of add-ons to expand Tokeet in whatever way you like.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/wide-range-of-plans.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Useful Integrations</h4>
                                        <p>
                                            We understand that there are tools and other platforms that you may already be using to run your business, so we’re constantly integrating with other providers.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/user-success.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="title-flx-box">
                        <h1>
                            What We<br />
                            Believe
                        </h1>
                        <p>
                            Creating software is as much an art<br /> 
                            as it is a science.
                        </p>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-6 img-holder order-last order-md-first">
                            <img src={require('../../assets/images/what-we-believe.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 content">
                            <p>
                                Creating software is as much an art as it is a science. Everyone has their own personal interpretation of the right answer and no two solutions are exactly alike. At Tokeet we embrace this idea and strive to always focus on the user. We believe in making the complex simple and that a simple solution will always be favored over a complex one. That’s why our software looks and feels simple yet solves some very complex workflow problems.
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/workflow-platform.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Get started quickly</h4>
                                        <p>
                                            We believe that customer support is a key part of our solution and so we offer many resources to get you going quickly.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/host-of-add-ons.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Helpful guides</h4>
                                        <p>
                                            We’ve created in-application contextual help so you learn each key feature of Tokeet.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/icons/wide-range-of-plans.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Video tutorials</h4>
                                        <p>
                                            We’ve also put together a series of video tutorials for those who prefer sound and motion.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default About;