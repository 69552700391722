import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/privacy.scss';

function TermsService(){
    PageTitle('Tokeet | Terms of Service');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('privacy-terms-layout')
        return () => {
          document.body.classList.remove('privacy-terms-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">
                        <h1>Tokeet Terms of Service</h1>
                    </div>
                    <div className="col-auto">
                        <h4>Last modified: March 28th, 2020</h4>
                    </div>
                </div>
            </div>
        </section>

        <section className="content-holder">
            <div className="container">
                <div className="container-box">
                    <div className="content-box">
                        <p>
                            By signing up for a Tokeet Account (as defined below) or by using any Tokeet Services (as defined below), you are agreeing to be bound by the following terms and conditions (the “Terms of Service”).
                            As used in these Terms of Service, “we”, “us” and “Tokeet” means Tokeet Inc.<br/>
                            <br/>
                            The services offered by Tokeet under the Terms of Service include various products and services to help you manage your rental properties, whether online, in person, or both. Any such services offered by Tokeet are referred to in these Terms of Services as the “Service”. Any new features or tools which are added to the current Service shall be also subject to the Terms of Service. You can review the current version of the Terms of Service at any time at <a href="https://www.tokeet.com/terms-of-service">www.tokeet.com/terms-of-service</a>. Tokeet reserves the right to update and change the Terms of Service by posting updates and changes to the Tokeet website. You are advised to check the Terms of Service from time to time for any updates or changes that may impact you. and if you do not accept such amendments, you must cease using the Service.<br/>
                            <br/>
                            You must read, agree with and accept all of the terms and conditions contained or expressly referenced in these Terms of Service, including Tokeet’s Acceptable Use Policy (“AUP”) and Privacy Policy, and, if applicable, the Tokeet API License and Terms of Use (“API Terms”) before you may sign up for a Tokeet Account or use any Tokeet Service.<br/>
                        </p>
                        <h2>1. Acceptance of Terms</h2>
                        <p>
                            <strong>1.1</strong> Tokeet.com (referred to here as "Tokeet") provides its Service (as defined below) to our users (referred to as You) through its web site located primarily at www.tokeet.com (the "Site"), subject to this Terms of Service agreement ("TOS"). By accepting this TOS or by accessing or using the Service or Site, You acknowledge that you have read, understand, and agree to be bound by this TOS. If You are entering into this TOS on behalf of a company or other legal entity, You represent that You have the authority to bind such entity and its affiliates to these TOS, in which case the terms "you" or "your" shall refer to such entity and its affiliates. If You do not have such authority, or if you do not agree with these TOS, You must not accept these TOS and may not use the Service.
                        </p>
                        <ul>
                            <li>To access and use the Services, you must register for a Tokeet account (“Account”) by providing your full legal name, current address, phone number, a valid email address, and any other information indicated as required. Tokeet may reject your application for an Account, or cancel an existing Account, for any reason, in our sole discretion.</li>
                            <li>You must be the older of: (i) 18 years, or (ii) at least the age of majority in the jurisdiction where you reside and from which you use the Services to open an Account.</li>
                            <li>You confirm that you are receiving any Service provided by Tokeet for the purposes of carrying on a business activity and not for any personal, household or family purpose.</li>
                            <li>You acknowledge that Tokeet will use the email address you provide on opening an Account or as updated by you from time to time as the primary method for communication with you.</li>
                            <li>You are responsible for keeping your password secure. Tokeet cannot and will not be liable for any loss or damage from your failure to maintain the security of your Account and password.</li>
                            <li>You are responsible for all activity and content such as photos, images, videos, graphics, written content, audio files, code, information, or data uploaded, collected, generated, stored, displayed, distributed, transmitted or exhibited on or in connection with your Account (“Materials”).</li>
                            <li>A breach or violation of any term in the Terms of Service, including the AUP, as determined in the sole discretion of Tokeet may result in an immediate termination of your Service and closure of your Account.</li>
                        </ul>
                        <p>
                            <strong>1.2</strong> Tokeet may change this TOS from time to time without prior notice. You can review the most current version of this TOS at any time at <a href="https://www.tokeet.com/terms-of-service">www.tokeet.com/terms-of-service</a>. The revised terms and conditions will become effective upon posting and if You use the Service after that date, we will treat your use as acceptance of the revised terms and conditions. If any change to this TOS is not acceptable to You, your only remedy is to stop accessing and using the Service.
                        </p>
                        <h2>2. Description of Service</h2>
                        <p>
                            The "Service" includes (a) the Site, (b) the on-demand Tokeet vacation rental management system, tools and services provided through the Site and by the Tokeet API made available via the Site, and (c) all software, data, text, images, sounds, video, and content made available through the Site or Service, or developed via the Tokeet API (collectively referred to as the "Content"). Any new features added to or augmenting the Service are also subject to this TOS.
                        </p>
                        <h2>3. General Conditions/ Access and Use of the Service</h2>
                        <p>
                            <strong>3.1</strong> Subject to the terms and conditions of this TOS, You may access and use the Service only for your internal business purposes as contemplated by the Agreement. You shall not (a) license, sublicense, sell, resell, rent, lease, transfer, assign, distribute, time share or otherwise commercially exploit or make the Service available to any third party, other than as expressly permitted by this TOS; (b) use the Service to process data on behalf of any third party, or (c) use the Service in any unlawful manner or in any manner that interferes with or disrupts the integrity or performance of the Service and its components.
                        </p>
                        <p>
                            <strong>3.2</strong> Subject only to the limited right to access and use the Service expressly granted to You under this TOS, all rights, title and interest in and to the Service and its components will remain with and belong exclusively to Tokeet. You shall not modify, adapt or hack the Service to falsely imply any sponsorship or association with Tokeet, or otherwise attempt to gain unauthorized access to the Service or its related systems or networks.
                        </p>
                        <p>
                            <strong>3.3</strong> You are responsible for all information, data, text, messages or other materials that You post or otherwise transmit via the Service. You are responsible for maintaining the confidentiality of your login and Account and are fully responsible for any and all activities that occur under your login or Account. You agree and acknowledge that your login may only be used by one (1) person - a single login shared by multiple people is not permitted and constitutes a violation of the TOS. You may create separate logins for as many people as your plan allows. Tokeet reserves the right to access any or all your accounts in order to respond to your requests for customer support.
                        </p>
                        <p>
                            <strong>3.4</strong> You understand that the technical processing and transmission of the Service, including your content, may involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices and (c) transmission to Tokeet's third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to operate and maintain the Service.
                        </p>
                        <p>
                            <strong>3.5</strong> The failure of Tokeet to exercise or enforce any right or provision of this TOS shall not be a waiver of that right. You acknowledge that this TOS is a contract between You and Tokeet, even though it is electronic and is not physically signed by you and Tokeet, and it governs your use of the Service and takes the place of any prior agreements between You and Tokeet.
                        </p>
                        <p>
                            <strong>3.6</strong>  
                            In the event of a dispute regarding Account ownership, we reserve the right to request documentation to determine or confirm Account ownership. Documentation may include, but is not limited to, a scanned copy of your business license, government issued photo ID, the last four digits of the credit card on file, your status as an employee of an entity, etc.
                        </p>
                        <p>
                            <strong>3.7</strong> Tokeet retains the right to determine, in our sole judgment, rightful Account ownership and transfer an Account to the rightful owner. If we are unable to reasonably determine the rightful owner, without prejudice to our other rights and remedies, Tokeet reserves the right to temporarily disable an Account until resolution has been determined between the disputing parties.
                        </p>
                        <h2>4. Trademarks</h2>
                        <p>
                            <strong>4.1</strong> Tokeet&reg; and Tokeet's various logos used or displayed on the Service are trademarks of Tokeet and you may only use these trademarks or logos for promotional purposes to identify yourself as a customer or user of the Tokeet products and services, provided you do not attempt to claim ownership of the marks by incorporating any of them within your names or offerings.
                        </p>
                        <p>
                            <strong>4.2</strong> No right, express or implied, is granted to You to use in any manner the name or any other trade name, logo, or trademark of Tokeet. You are hereby restricted from using the Tokeet name or logo for any reason unless expressly permitted in writing from Tokeet.
                        </p>
                        <h2>5. Cost, Payment and Refunds</h2>
                        <p>
                            <strong>5.1</strong> The basic features of the Service (referred to as the Base Module) is offered free of charge, or any other costs, to You. Tokeet offers additional modules to You at a specified cost. These additional modules are optional and will not affect your use of the Base Module if You choose not to purchase them. Tokeet reserves the right to add features to the Base Module or change the Base Module without prior notice, but will do its best to keep You updated and informed of any changes. Tokeet also reserves the right to add or remove additional modules, change the configuration of all modules, or stop offering any module at its discretion and without prior notice. Tokeet will, however, do its very best to keep You informed and prepare for any of these changes. No credit card or payment is required for the Base Module.
                        </p>
                        <p>
                            <strong>5.2</strong> The Service is made available on a pay-as-you-go basis. If You have provided credit card details to Tokeet and have elected to purchase an additional module with a paid plan, You will be billed monthly starting immediately after your selection. Regardless of your billing cycle, there are no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open Account. In order to treat everyone equally, no exceptions will be made. For any upgrade or downgrade in plan level or additional module, your credit card will automatically be charged the new rate on your next billing cycle. Downgrading your Account may cause the loss of content, features, or capacity of your Account. Tokeet does not accept any liability for such loss. Prices of all Services, including but not limited to monthly fees to the Service, are subject to change at any time.
                        </p>
                        <p>
                            <strong>5.3</strong> Tokeet provides an interface for the Account owner (as defined in the sign-up procedure) to change credit card information (e.g., upon card renewal). The Account owner will receive an email receipt upon each credit card charge. The receipts are also available for the Account owner from within the application. Tokeet uses a third-party intermediary to manage credit card processing and this intermediary is permitted to store, retain, or use your billing information except to process your credit card information for Tokeet. Please visit <a href="https://www.tokeet.com/privacy.html">www.tokeet.com/privacy.html</a> to understand how Tokeet collects and uses personal information.
                        </p>
                        <h2>6. Cancellation and Termination</h2>
                        <p>
                            <strong>6.1</strong> You are responsible for properly canceling your Account. The Account owner (as defined in the sign-up procedure) can cancel the Account at any time by clicking on the Manage Billing link in the global navigation bar at the top right of the screen. All of your property setup and reservation data will NOT be immediately deleted from the Service upon cancellation, unless specifically requested. That information will be stored for a period of at least one year, at which point, if the service is not re-established by the Account holder, the Account will be closed and the data removed from our servers. This information cannot be recovered once your Account is closed. If You cancel the Service before the end of your current paid up month, your cancellation will take effect immediately and you will not be charged again.  If your Account cannot be deleted by you, this use will constitute an instruction to Tokeet to delete your Account from Tokeet's systems in accordance with applicable law.
                        </p>
                        <p>
                            <strong>6.2</strong> Tokeet reserves the right to (i) modify or discontinue, temporarily or permanently, the Service (or any part thereof) and (ii) refuse any and all current and future use of the Service, suspend or terminate your Account (any part thereof) or use of the Service and remove and discard any of your content in the Service, for any reason, including, if Tokeet believes that You have violated these TOS. Tokeet will use best efforts to publish on the Site or notify You about any planned downtime of the Service, and will use all reasonable efforts to contact you directly via email to warn you prior to suspension or termination of your Account. Any suspected fraudulent, abusive, or illegal activity that may be grounds for termination of your use of Service, may be referred to appropriate law enforcement authorities. Tokeet shall not be liable to You or any third party for any modification, suspension or discontinuation of the Service.
                        </p>
                        <p>
                            <strong>6.3</strong> Any false claims, assertions, or accusations publicly or privately made by You regarding Tokeet, Tokeet employees, or the Service will be considered grounds for termination of your Account. If you are found to have breached these terms and fail to cure such breach within seven (7) days following receipt of written, digital notice from Tokeet, Tokeet shall immediately terminate your Account. It is understood that written notice is not a required precursor or prerequisite to Account termination. If said breach is determined by Tokeet to be especially egregious, Tokeet reserves the right to immediately terminate your Account without any notice or warning.
                        </p>
                        <p>
                            <strong>6.4</strong> Verbal or written abuse of any kind (including threats of abuse or retribution) of any Tokeet customer, Tokeet employee, member, or officer will result in immediate Account termination.
                        </p>
                        <h2>7. Confidentiality</h2>
                        <p>
                            <strong>7.1</strong> “Confidential Information” shall include, but shall not be limited to, any and all information associated with a party’s business and not publicly known, including specific business information, technical processes and formulas, software, customer lists, prospective customer lists, names, addresses and other information regarding customers and prospective customers, product designs, sales, costs (including any relevant processing fees), price lists, and other unpublished financial information, business plans and marketing data, and any other confidential and proprietary information, whether or not marked as confidential or proprietary. Tokeet's Confidential Information includes all information that you receive relating to us, or to the Service, that is not known to the general public including information related to our security program and practices. It also includes information received from any of our employees or support persons which is not already publicly available or otherwise well known.
                        </p>
                        <p>
                            <strong>7.2</strong> Each party agrees to use the other party’s Confidential Information solely as necessary for performing its obligations under these Terms of Service and in accordance with any other obligations in these Terms of Service including this Section 6. Each party agrees that it shall take all reasonable steps, at least substantially equivalent to the steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of any such Confidential Information, other than (i) by or to its employees, agents and subcontractors who must have access to such Confidential Information to perform such party’s obligations hereunder, who each shall treat such Confidential Information as provided herein, and who are each subject to obligations of confidentiality to such party that are at least as stringent as those contained herein; or (ii) as required by any law, regulation, or order of any court of proper jurisdiction over the parties and the subject matter contained in these Terms of Service, provided that, if legally permitted, the receiving party shall give the disclosing party prompt written notice and use commercially reasonable efforts to ensure that such disclosure is accorded confidential treatment. Confidential Information shall not include any information that the receiving party can prove: (A) was already in the public domain, or was already known by or in the possession of the receiving party, at the time of disclosure of such information; (B) is independently developed by the receiving party without use of or reference to the other party’s Confidential Information, and without breaching any provisions of these Terms of Service; or (C) is thereafter rightly obtained by the receiving party from a source other than the disclosing party without breaching any provision of these Terms of Service.
                        </p>
                        <p>
                            <strong>7.3</strong> A breach of confindetiality as defined within the Terms of Service may result in immediate Account termination.
                        </p>
                        <h2>8. Disclaimer of Warranties</h2>
                        <p>
                            THE SERVICE, INCLUDING THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT ANY WARRANTIES OF ANY KIND, TO THE FULLEST EXTENT PERMITTED BY LAW AND Tokeet EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT Tokeet DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR VIRUS-FREE AND NO INFORMATION OR ADVICE OBTAINED BY YOU FROM Tokeet OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THIS TOS.
                        </p>
                        <h2>9. Limitation of Liability</h2>
                        <p>
                            <strong>9.1</strong> UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN CONTRACT, TORT, OR OTHERWISE) SHALL Tokeet BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA OR BUSINESS INTERRUPTION. IN ADDITION, Tokeet SHALL NOT BE LIABLE FOR ANY DIRECT DAMAGES, COSTS, LOSSES OR LIABILITIES IN EXCESS OF THE MONTHLY SERVICE FEE PAID OR PAYABLE BY YOU FOR THE ONE MONTH PRECEDING THE TIME OF ANY CLAIM FOR MONETARY DAMAGES OR ONE HUNDRED ($100) U.S. DOLLARS. THE PROVISIONS OF THIS SECTION ALLOCATE THE RISKS UNDER THIS TOS BETWEEN THE PARTIES, AND THE PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER INTO THIS AGREEMENT.
                        </p>
                        <p>
                            <strong>9.2</strong> Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. IN THESE STATES, Tokeet's LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
                        </p>
                        <h2>10. Assignment</h2>
                        <p>Tokeet may assign or transfer this TOS, in whole or in part, without restriction.</p>
                        <h2>11. Export Compliance</h2>
                        <p>
                            You are responsible for complying with any applicable laws, rules, or regulations governing the export of the Service or any of its components.
                        </p>
                        <h2>12. Governing Law</h2>
                        <p>
                            These TOS shall be governed by the laws of the State of Delaware, United States of America without regard to the principles of conflicts of law. You hereby expressly agree to submit to the exclusive personal jurisdiction of the federal and state courts of the State of Delaware for the purpose of resolving any dispute relating to your access to or use of the Service.
                        </p>
                        <p>
                            <strong>
                                You can contact Tokeet at anytime at: <a href="mailto:support@tokeet.com">support@tokeet.com</a>.
                            </strong>  
                        </p>
                    </div>
                </div>                
            </div>
        </section>

        </>
    );
}

export default TermsService;