import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";
import { Helmet } from 'react-helmet';

import '../../assets/scss/reviews.scss';

function TokeetReviews(){
    PageTitle('Tokeet | Reviews and Customer Feedback');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('reviews-page')
        return () => {
          document.body.classList.remove('reviews-page')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-5 banner-caption">
                        <h1>
                            Tokeet Reviews
                        </h1>
                        <p>
                            See what other users are saying about Tokeet <br className="d-none d-lg-block" />
                            and Tokeet Mobile around the web.
                        </p>
                    </div>
                    <div className="col-md-5 banner-holder order-first order-md-last">
                        <img src={require('../../assets/images/tokeet-reviews-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="reviews-holder">
            <div className="container">
                <Helmet>
                    <script src="https://apps.elfsight.com/p/platform.js" defer></script>
                </Helmet>                
                <div className="elfsight-app-511685c6-a8e8-4157-bb11-692504b78dfd"></div>
            </div>
        </section>

        <section className="looking-more-customer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">
                        <h1>
                            Looking for more<br /> 
                            customer experiences?
                        </h1>
                        <p>
                            Feel free to browse through any of these<br className="d-none d-lg-block" /> 
                            trusted online review sites:
                        </p>
                        <div className="img-holder">
                            <a href="https://www.trustpilot.com/review/tokeet.com" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/trustpilot.png')} alt="" />
                            </a>                         
                            <a href="https://www.reviews.io/search?search=tokeet" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/reviews.png')} alt="" />
                            </a>                            
                            <a href="https://www.g2.com/products/tokeet/reviews" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/g2.png')} alt="" />
                            </a>
                            <br className="d-none d-lg-block" />  
                            <a href="https://www.capterra.com/p/155340/Tokeet/#reviews" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/capterra.png')} alt="" />
                            </a>                          
                            <a href="https://play.google.com/store/apps/details?id=com.app.tokeet" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/google-play.png')} alt="" />
                            </a>
                            <a href="https://www.getapp.com/hospitality-travel-software/a/tokeet/reviews/" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/logos/getapp.png')} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="we-want-hear-from-you">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6 col-lg-5">
                        <h1>We want to hear from you!</h1>
                        <p>
                            Here at Tokeet, customer experience is our priority. User testimonials provide us with valuable insights and the means to improve. 
                            <br /><br />
                            Our mission is to exceed our customers’ expectations by anticipating their needs. Your voice will allow us to continue providing exceptional service.
                        </p>
                    </div>
                    <div className="col-md-6 img-holder">
                        <img src={require('../../assets/images/hear_from_you.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default TokeetReviews;