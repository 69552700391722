import { React, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/home.scss';

function Home(){
    PageTitle('Tokeet | Advanced Vacation Rental Management Software');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('homepage')
        return () => {
          document.body.classList.remove('homepage')
        }
    }, [])
    
    const [productUrl, setProductUrl] = useState("https://www.useautomata.com/");
    // Getting mobile devices
    // const [userIsMobile, setUserIsoMbile] = useState(true);
    // useEffect(() => {
    //     window.innerWidth < 575 ? setUserIsoMbile(true) : setUserIsoMbile(false);
    // }, [userIsMobile]);
    // Spinner
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>           
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-5 banner-caption">
                            <h1>
                                Increase your bookings and earn more with Tokeet.
                            </h1>
                            <p>
                                Our suite of best in class products helps you get more bookings, automate your workflow, set dynamic rates and coordinate with service providers.
                            </p>
                            <p className="btn-holder">
                                <a href="https://www.youtube.com/embed/iB079WoO9uQ" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                        </div>
                        <div className="col-md-7 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/home-banner.png')}  className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="channels-holder">
                <div className="container">
                    <div className="channels-flx-log-box">
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/booking.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/agoda.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/airbnb.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/expedia.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/homes_villas.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/rental-united.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/holidu.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/channels/vrbo.png')} alt="" />
                        </div>
                    </div>
                    <div className="row owners-box-holder">
                        <div className="col-sm-6 col-md-6 col-lg-3 box-holder">
                            <div className="box">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/icons/single_rental_owners.png')} alt="" />
                                </div>
                                <div className="box-content">
                                    <h4>Single Rental Owners</h4>
                                    <p>
                                    If you’re an owner starting with a single rental we can help you build a great website for your property and get connected with the booking channels that matter the most.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-3 box-holder">
                            <div className="box">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/icons/multiple_rental_owners.png')} alt="" />
                                </div>
                                <div className="box-content">
                                    <h4>Multiple Rental Owners</h4>
                                    <p>
                                        For owners who have more than one rental we can save you time and
                                        help create an amazing guest experience with our products that
                                        are built with you in mind.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-3 box-holder">
                            <div className="box">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/icons/smb_business.png')} alt="" />
                                </div>
                                <div className="box-content">
                                    <h4>SMB Business</h4>
                                    <p>
                                        We help small and medium-sized businesses scale their operations efficiently through technology. 
                                        Automate most of your operations and generate more revenue with Tokeet.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-3 box-holder">
                            <div className="box">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/icons/large_enterprise.png')} alt="" />
                                </div>
                                <div className="box-content">
                                    <h4>Large Enterprise</h4>
                                    <p>
                                        Tokeet is the go-to choice for large-scale businesses that need to gain maximum efficiency and profitability. We have
                                        the tools you need to maximize your booking revenue, automate your operations, and manage your staff.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="short-info-holder">
                <div className="container">
                    <div className="short-info">
                        <div className="info">
                            <h4>1000+</h4>
                            <p>Rentals <img src={require('../../assets/images/icons/rentals.png')} alt="" /></p>
                        </div>
                        <div className="info">
                            <h4>95+</h4>
                            <p>Countries <img src={require('../../assets/images/icons/countries.png')} alt="" /></p>
                        </div>
                        <div className="info">
                            <h4>10+</h4>
                            <p>Products <img src={require('../../assets/images/icons/products.png')} alt="" /></p>
                        </div>
                        <div className="info">
                            <h4>50+</h4>
                            <p>Integrations <img src={require('../../assets/images/icons/integrations.png')} alt="" /></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-products-holder">
                <div className="container">
                    <div className="title">
                        <h4>OUR PRODUCTS</h4>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-5">
                            <h1>Want to check out all our products?</h1>
                            <div className="nav-tab-holder" role="tablist">
                                <button className="btn active" data-bs-toggle="pill" data-bs-target="#automata" type="button" role="tab" aria-selected="true" onClick={() => setProductUrl("https://www.useautomata.com/")}>
                                    <img src={require('../../assets/images/products/logos/automata-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/automata.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#owners" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("http://www.tokeet.com/solutions/owner-manager")}>
                                    <img src={require('../../assets/images/products/logos/owners-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/owners.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#checklist" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://usechecklist.com/")}>
                                    <img src={require('../../assets/images/products/logos/checklist-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/checklist.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#rategenie" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://rategenie.io/")}>
                                    <img src={require('../../assets/images/products/logos/rategenie-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/rategenie.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#sympl" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://sympl.cm/")}>
                                    <img src={require('../../assets/images/products/logos/sympl-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/sympl.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#signature" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://www.usesignature.com/")}>
                                    <img src={require('../../assets/images/products/logos/signature-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/signature.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#tokeet" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://www.tokeet.com/")}>
                                    <img src={require('../../assets/images/products/logos/tokeet-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/tokeet.png')} className="e-active" alt="" />
                                </button>
                                <button className="btn" data-bs-toggle="pill" data-bs-target="#webready" type="button" role="tab" aria-selected="false" onClick={() => setProductUrl("https://usewebready.com/")}>
                                    <img src={require('../../assets/images/products/logos/webready-w.png')} className="d-active" alt="" />
                                    <img src={require('../../assets/images/products/logos/webready.png')} className="e-active" alt="" />
                                </button>
                            </div>
                            <div className="btn-holder d-none d-md-block">
                                <a href={productUrl} className="btn btn-visit-web" target="_blank" rel="noreferrer">
                                    <span>Visit Website</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>                        
                        <div className="col-md-6">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="automata" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Automate your booking process flawlessly <br className="d-none d-lg-block" />
                                                with pre-defined and customized templates.
                                            </h4>  
                                        </div> 
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductAutomata" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductAutomata" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductAutomata" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductAutomata" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/automata-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/automata-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/automata-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductAutomata" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductAutomata" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="owners" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Access tools and resources <br className="d-none d-lg-block" />
                                                to easily manage your property owners.
                                            </h4>  
                                        </div>  
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductOwners" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductOwners" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductOwners" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductOwners" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/owners-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/owners-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/owners-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductOwners" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductOwners" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="checklist" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Automate your property care <br className="d-none d-lg-block" />
                                                and schedule service providers with ease. 
                                            </h4>   
                                        </div>    
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductChecklist" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductChecklist" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductChecklist" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductChecklist" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/checklist-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/checklist-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/checklist-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductChecklist" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductChecklist" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="rategenie" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Data driven algorithms will help you <br className="d-none d-lg-block" />
                                                maximize your profits with the perfect rates daily.
                                            </h4>       
                                        </div> 
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductRategenie" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductRategenie" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductRategenie" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductRategenie" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/rategenie-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/rategenie-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/rategenie-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductRategenie" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductRategenie" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="sympl" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Sync, Scale, Sympl. Save time and money managing your rentals, the Sympl way. Built for owners with 1 to 3 rentals.
                                            </h4> 
                                        </div>
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductSympl" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductSympl" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductSympl" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductSympl" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/sympl-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/sympl-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/sympl-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductSympl" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductSympl" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="signature" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Collect guest signatures, government IDs, <br className="d-none d-lg-block" />
                                                or post-stay feedback while on the go.
                                            </h4>   
                                        </div>
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductSignature" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductSignature" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductSignature" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductSignature" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/signature-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/signature-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/signature-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductSignature" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductSignature" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                      
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="tokeet" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Manage your vacation rental business with <br className="d-none d-lg-block" />
                                                a powerful suite of products created with your needs in mind.
                                            </h4>   
                                        </div>    
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductTokeet" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductTokeet" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductTokeet" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductTokeet" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/tokeet-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/tokeet-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/tokeet-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductTokeet" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductTokeet" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="webready" role="tabpanel">
                                    <div className="row">
                                        <div className="col-12">
                                            <h4>
                                                Build a beautiful website with just a <br className="d-none d-lg-block" />
                                                few clicks and maximize your direct bookings.
                                            </h4>   
                                        </div>                                         
                                        <div className="col-12 order-first order-md-last">                                            
                                            <div id="carouselProductWebready" className="carousel carousel-dark slide" data-bs-ride="carousel">
                                                <div className="carousel-indicators">
                                                    <button type="button" data-bs-target="#carouselProductWebready" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                    <button type="button" data-bs-target="#carouselProductWebready" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                    <button type="button" data-bs-target="#carouselProductWebready" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                </div>
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active" data-bs-interval="3000">
                                                        <img src={require('../../assets/images/products/sliders/webready-01.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/webready-02.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                    <div className="carousel-item" data-bs-interval="2000">
                                                        <img src={require('../../assets/images/products/sliders/webready-03.png')} className="d-block w-100" alt="" />
                                                    </div>
                                                </div>
                                                
                                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselProductWebready" data-bs-slide="prev">
                                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Previous</span>
                                                </button>
                                                <button className="carousel-control-next" type="button" data-bs-target="#carouselProductWebready" data-bs-slide="next">
                                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Next</span>
                                                </button>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>

                            <div className="btn-holder btn-holder-flx d-block d-md-none">
                                <a href={productUrl} className="btn btn-visit-web" target="_blank" rel="noreferrer">
                                    <span>Visit Website</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-services-holder">
                <div className="container">
                    <div className="flx-log-box">
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/dropbox.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/duve.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/guestflow.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/insuraguest.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/mailchimp.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/minut.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/pricelabs.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/slack.png')} alt="" />
                        </div>
                        <div className="logo-box">
                            <img src={require('../../assets/images/our-services/twilio.png')} alt="" />
                        </div>
                    </div>
                    <div className="title-holder">
                        <h4>OUR SERVICES</h4>
                        <h1>
                            Grow your vacation rental business <br className="d-none d-lg-block" />
                            and simplify your workflow with <br className="d-none d-lg-block" />
                            our wide range of services.
                        </h1>
                    </div>
                    <div className="flex-content-box">
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/channel-manager.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Channel Manager</h4>
                                <p>
                                    Powerful tools to help you manage and grow your vacation rental business.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/channel-manager">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/rate-management.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Rate Management</h4>
                                <p>
                                    Automatically update your rates daily with the perfect price across multiple channels.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/dynamic-pricing">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/invoice_payments.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Invoice & Payments</h4>
                                <p>
                                    Create and send invoices to your guests automatically and accept payments.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/invoice-payments">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/business-automation.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Business Automation</h4>
                                <p>
                                    Automata executes all the tasks related to a guest booking, so you can focus on growing your rental business.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/automation">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/website-builder.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Website Builder</h4>
                                <p>
                                    Accept bookings from your own rental website and skip the booking fees.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/direct-bookings">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/our-services/owner-manager.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Owner Manager</h4>
                                <p>
                                    Create owners, statements, booking reports and easily invoice your owners.
                                </p>
                                <div className="btn-holder">
                                    <Link to="/solutions/owner-manager">
                                        <span>Learn More</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="testimonials-holder">
                <div className="container">
                    <div className="title-holder">
                        <h4>TESTIMONIALS</h4>
                        <h1>
                            Don’t take our word for it. Here’s<br className="d-none d-lg-block" />
                            what our customers think.
                        </h1>
                    </div>
                    <div className="row total-reviews justify-content-center">
                        <div className="col-12 col-sm-6 col-md-4 review-box">
                            <a href="https://www.capterra.com/p/155340/Tokeet/#reviews" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/capterra-banner.png')} alt="" />
                            </a>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 review-box">
                            <a href="https://www.trustpilot.com/review/tokeet.com" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/trustpilot-banner.png')} alt="" />
                            </a>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 review-box">
                            <a href="https://www.getapp.com/hospitality-travel-software/a/tokeet/reviews/" target="_blank" rel="noreferrer">
                                <img src={require('../../assets/images/testimonials/getapp-banner.png')} alt="" />
                            </a>
                        </div>
                    </div>
                    <div id="carouselTestimonials" className="carousel carousel-dark slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselTestimonials" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>I highly recommend Tokeet</strong><br />
                                                We only use airbnb as a booking agent as well as direct bookings to us but we still use Tokeet as a channel manager as it makes life so much easier using this platform.                                                 
                                                <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Walsh Shane</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/trustpilot.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>I highly recommend Tokeet to all…</strong><br />
                                                I highly recommend Tokeet to all property managers, or short term rental owners. The customer service is always impeccable and they have comprehensive directions in video, articles, and chat's <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Michelle Bentley</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/trustpilot.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">                  
                                    <div className="col-md-6">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>Love It! make my life so much easier</strong><br />
                                                I evaluated 4 rental mgmt software before deciding on Tokeet. I used the trial time frame for each and choose Tokeet because of their easy of use, value and customer support. <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Emilio Mignanelli</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/trustpilot.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>Excellent customer service</strong><br />
                                                I've been using this software for about 4 years and though im a bit of a technophobe I have managed to learn to use most of the processes and always learning new things. <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Liz E.</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/capterra.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>I love Tokeet.</strong><br />
                                                I love Tokeet. It's extremely easy to use and the customer support is fantastic so if there's anything I need to know I just drop them a quick line and someone gets back to me very quickly. <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Joanna Gregory</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/reviews_io.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>            
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="testimonial-holder">
                                            <p>
                                                <strong>Simple and Easy to use, amazing customer service.</strong><br />
                                                Overall very good, it brings together everything I need. I'm about to expand to 13 properties with hopes to reach 20 next year and 
                                                Tokeet will continue to provide <Link to="/tokeet-reviews">Read more</Link>
                                            </p>
                                            <div className="author-flx-box">
                                                <div className="auth">
                                                    <img src={require('../../assets/images/testimonials/user-icon.png')} alt="" />
                                                    <span>Ben N.</span>
                                                </div>
                                                <div className="company">
                                                    <img src={require('../../assets/images/testimonials/capterra.png')} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;