import { React } from "react";
import { Link } from 'react-router-dom';

function Footer(){
    return(
        <>
        <section className="ftr-need-help-holder">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-md-6 img-holder order-last order-md-first">
                        <img src={require('../../assets/images/need_help.png')} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6 content-holder">
                        <h4>NEED HELP?</h4>
                        <h1>Our reps have answers.</h1>
                        <p>
                            Ask about Tokeet products, pricing, implementations, or anything else - our highly trained reps are standing by, ready to help.
                        </p>
                        <div className="btn-flx-holder">
                            <div>
                                <a href="https://www.tokeet.com/help/" className="view-more-btn" target="_blank" rel="noreferrer">
                                <span>Visit our help center</span>
                                <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="phone">
                                <span className="or">OR</span>
                                <span>1-855-4TOKEET</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer>
            <div className="footer-main">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-3 ftr-logo-holder text-center text-md-start">
                            <div className="logo-holder">
                                <Link to="/">
                                    <img src={require('../../assets/images/logo-gray.png')} alt="" />
                                </Link>
                            </div>
                            <p>
                                Tokeet is the perfect choice for vacation rental managers & owners who need total control.
                            </p>
                            <ul className="socail-icons justify-content-center  justify-content-md-start">
                                <li><a href="https://www.facebook.com/tokeetapp" target="_blank" rel="noreferrer" className="facebook">&nbsp;</a></li>
                                <li><a href="https://twitter.com/tokeetapp" target="_blank" rel="noreferrer" className="twitter">&nbsp;</a></li>
                                <li><a href="https://www.linkedin.com/company/tokeet/" target="_blank" rel="noreferrer" className="linkedin">&nbsp;</a></li>
                                <li><a href="https://www.youtube.com/channel/UCJLdxLxXdXjKZiKMtUcL6kw" target="_blank" rel="noreferrer" className="youtube">&nbsp;</a></li>
                                <li><a href="https://www.instagram.com/tokeetapp/" target="_blank" rel="noreferrer" className="instagram">&nbsp;</a></li>
                            </ul>
                            <p className="email-phone">
                                <a href="mailto:support@tokeet.com">support@tokeet.com</a> <br />
                                <span className="phone">1-855-4TOKEET</span>
                            </p>
                        </div>
                        <div className="col-md-9 col-lg-8 ftr-navs-holder">
                            <div className="row">
                                <div className="col-6 col-sm-3">
                                    <h4>Solutions</h4>
                                    <ul>
                                        <li>
                                            <Link to="/solutions/channel-manager">Channel Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/automation">Automations</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/direct-bookings">Direct Bookings</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/invoice-payments">Invoicing</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/dynamic-pricing">Dynamic Pricing</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/staff-management">Staff Management</Link>
                                        </li>
                                        <li>
                                            <Link to="/solutions/owner-manager">Owner Manager</Link>
                                        </li> 
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <h4>COMPANY</h4>
                                    <ul>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/pricing">Pricing</Link>
                                        </li>
                                        <li>
                                            <a href="https://www.dropbox.com/sh/c4efq11mlts757b/AACU_jIxUs16kK7m7Y2cVd_7a?dl=0"  target="_blank" rel="noreferrer">Press Kit</a>
                                        </li>
                                        <li>
                                            <Link to="/terms-of-service" target="_blank">Terms of Service</Link>
                                        </li>
                                        <li>
                                            <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <h4>RESOURCES</h4>
                                    <ul>
                                        <li>
                                            <a href="https://www.tokeet.com/blog/"  target="_blank" rel="noreferrer">Blog</a>
                                        </li>
                                        <li>
                                            <Link to="/tokeet-reviews">Tokeet Reviews</Link>
                                        </li>
                                        <li>
                                            <Link to="/case-studies">Case Studies</Link>
                                        </li>
                                        <li>
                                            <Link to="/affiliate">Become a Tokeet Affiliate</Link>
                                        </li>
                                        <li>
                                            <Link to="/affiliate/faq">Tokeet Affiliate FAQ</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6 col-sm-3">
                                    <h4>SUPPORT</h4>
                                    <ul>
                                        <li>
                                            <a href="https://www.tokeet.com/help/" target="_blank" rel="noreferrer">Help Center</a>
                                        </li>
                                        <li>
                                            <Link to="/solutions/premium-services">Premium Services</Link>
                                        </li>
                                        <li>
                                            <a href="https://www.tokeet.com/community/" target="_blank" rel="noreferrer">Community</a>
                                        </li>
                                        <li>
                                            <a href="https://changes.tokeet.com" target="_blank" rel="noreferrer">Change Log</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container">
                    <p>Copyright 2015-2022. Tokeet.com. <br className="d-md-none" />All Rights Reserved.</p>
                </div>
            </div>
        </footer>
        </>
    );
}

export default Footer;