import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function DirectBookings(){
    PageTitle('Tokeet | Direct Bookings for Short-Term Rental Managers');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 banner-caption">
                            <h1>
                                Get more direct bookings and avoid booking fees with a beautiful website for your short-term rental.
                            </h1>
                            <p>
                                Stand out online with a professional website for your short-term rental. Save money with your own booking engine and go beyond the channels. Impress your guests from the very first encounter and maximize the revenue from repeat visits.
                            </p>
                            <p className="btn-holder">
                                <a href="https://register.usewebready.com/account" className="btn btn-start-trial">
                                    Start Free Trial
                                </a>
                                <a href="https://youtu.be/LVcZJKONqTE" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                        </div>
                        <div className="col-md-6 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/direct-bookings-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="temp-content-holder">
                <div className="container">
                    <div className="dark-box-top">
                        <div className="title-holder mb-0">            
                            <h4>GET PROFESSIONAL FEATURES FOR YOUR WEBSITE</h4>            
                            <h1>
                                Create a website you are proud of, and customize<br className="d-none d-lg-block" /> it exactly the way you want. Start receiving online<br className="d-none d-lg-block" /> bookings today.
                            </h1>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Beautiful Templates
                                </h1>
                                <p>
                                    You won’t need any experience to create your website, simply select one of our beautiful, 
                                    high-quality templates then add your information and images. 
                                    There are many templates to choose from so you get exactly the style you want.
                                </p>
                                <a href="https://usewebready.com/templates.html" className="try-free-btn" target="_blank" rel="noreferrer">
                                    <span>Browse Templates</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/beautiful_templates.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/ssl_secured.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    SSL Secured
                                </h1>
                                <p>
                                    No need to worry about the security of your website. 
                                    Get professional grade security from the start with our 256 bit SSL encryption with a few clicks.
                                </p>
                                <a href="https://register.usewebready.com/account" className="try-free-btn"  target="_blank" rel="noreferrer">
                                    <span>Register Now</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Custom Domain
                                </h1>
                            <p>
                                Add that professional touch with a domain name that matches your business and brand. Easily purchase a custom domain with Webready and get a free SSL certificate.
                            </p>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/custom_domain.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/accept_online_booking.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Accept Online Bookings
                                </h1>
                                <p>
                                    Start receiving online bookings with a custom, secure <br className="d-none d-lg-block" />booking engine that matches your website. Say goodbye <br className="d-none d-lg-block" />to channel bookings fees and maximize revenue from<br className="d-none d-lg-block" /> repeat visits.<br className="d-none d-lg-block" /><br className="d-none d-lg-block" />
                                </p>
                                <a href="https://register.usewebready.com/account" className="try-free-btn"  target="_blank" rel="noreferrer">
                                    <span>Register Now</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Sync with channels
                                </h1>
                                <p>
                                    No need to worry about keeping track of your rates and availability on your website, 
                                    they are automatically synced with all your channels and channel managers. 
                                </p>
                                <a href="https://register.usewebready.com/account" className="try-free-btn"  target="_blank" rel="noreferrer">
                                    <span>Register Now</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/sync_with_channel.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/responsive-design.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Responsive Design
                                </h1>
                                <p>
                                    Your website will be beautiful on desktop, laptop, or<br className="d-none d-lg-block" /> mobile devices. All of our templates are fully responsive<br className="d-none d-lg-block" /> and search engine optimized.<br className="d-none d-lg-block" /><br className="d-none d-lg-block" />
                                </p>
                                <a href="https://register.usewebready.com/account" className="try-free-btn"  target="_blank" rel="noreferrer">
                                    <span>Register Now</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className="temp-sliders-holder">
                <div className="container">
                    <h4>STUNNING TEMPLATES</h4>
                    <div className="row justify-content-between">
                        <div className="col-md-6 col-lg-5">
                            <h1>
                                Choose from our set of best-in-class website templates.
                            </h1>
                        </div>
                        <div className="col-md-6">
                            <p>
                                Webready was created to be the easiest and most advanced vacation rental website builder on the market. Edit your live website with easy click and type functionality. What you see is what you get WYSWYG with our advanced website editor and page creators.
                            </p>
                            <a href="https://usewebready.com/templates.html" className="btn btn-browse-temp" target="_blank" rel="noreferrer">
                                <span>Browse Templates</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="carousel carousel-dark slide template-sliders" id="carouselBrowseTemp" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselBrowseTemp" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselBrowseTemp" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselBrowseTemp" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="5000">
                            <img src={require('../../assets/images/solutions/direct-booking-slides/1.png')} className="d-block w-100" alt="" />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={require('../../assets/images/solutions/direct-booking-slides/1.png')} className="d-block w-100" alt="" />
                        </div>
                        <div className="carousel-item" data-bs-interval="3000">
                            <img src={require('../../assets/images/solutions/direct-booking-slides/1.png')} className="d-block w-100" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="create-free-website">
                <div className="container">
                    <div className="title-holder">
                        <h4>
                            FOLLOW THESE 6 SIMPLE STEPS
                        </h4>
                        <h1>
                            How to create a<br />
                            free website
                        </h1>
                    </div>
                    <div className="flex-content-box">
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/sign-up-withwebready.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Sign up with<br /> Webready.</h4>
                                <p>
                                    Choose what kind of website you want to create.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/customize-template.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Customize a template <br />from our gallery.</h4>
                                <p>
                                    Choose your starting point.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/drag-drop.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Drag and drop 100s<br /> of design features.</h4>
                                <p>
                                    Add text, galleries, videos, and more.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/get-ready.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Get ready for <br />business.</h4>
                                <p>
                                    Add your rentals and get inquiries with our booking system.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/publish-your-website.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Publish your website<br /> and go live.</h4>
                                <p>
                                    Start building your professional online presence.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/sync-with-all.png')} alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Sync with all the<br /> channels.</h4>
                                <p>
                                    Sync your website bookings with your channel manager.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="btn-holder">
                        <a href="https://register.usewebready.com/account" className="btn" target="_blank" rel="noreferrer">
                            <span>Get Started</span>
                            <i className="bi bi-arrow-right"></i>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
}

export default DirectBookings;