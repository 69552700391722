import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function OwnerManager(){
    PageTitle('Tokeet | Vacation Rental Owner Management Tools');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 banner-caption">
                            <h1>
                                Owner Management
                            </h1>
                            <p>
                                Simplify managing your owners with owner invoices, statements, & booking reports and even have them pay online.
                            </p>
                            <p className="btn-holder">
                                <a href="https://register.tokeet.com/" className="btn btn-start-trial">
                                    Start Free Trial
                                </a>
                                <a href="https://www.youtube.com/embed/QXr8ZBbDQow" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                            <img src={require('../../assets/images/solutions/channel-partners-2.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/owner-manager-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="temp-content-holder">
                <div className="container">
                    <div className="dark-box-top">
                        <div className="title-holder">            
                            <h4>EFFORTLESS</h4>            
                            <h1>
                                Save hours each month and <br className="d-none d-lg-block" />
                                scale your business effortlessly.
                            </h1>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/automatic_payout.png')} alt="" />
                                </div>
                                <h4>
                                    Automatic Payout <br /> Calculation
                                </h4>
                                <p>
                                    Invoice your owners in just a few clicks each month and have your owners pay online.
                                    Create payout rules which automatically calculate how much each user is due for a booking, or 
                                    how much the owner owes you. These rules are automatically applied, 
                                    based on rule criteria, whenever you create a new statement or invoice. 
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/customize_invoices.png')} alt="" />
                                </div>
                                <h4>Customize Statements <br /> & Invoices</h4>
                                <p>
                                    Completely customize your statements and invoices with your own logo. You may add additional line items to each invoice and add fees and taxes as needed. Your documents are printable directly from the dashboard and you may hide your statements while you’re working on them.
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 box">
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/online_payment.png')} alt="" />
                                </div>
                                <h4>Accept Online <br /> Payments</h4>
                                <p>
                                    Invoicing and collecting payments from your owners has never been easier. Payments from owners are sent directly to your account and not through Tokeet. Tokeet uses your own Stripe account to collect payment on your behalf. With Stripe, you can charge almost any kind of credit or debit card.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Start Invoicing<br className="d-none d-lg-block" />
                                    In Minutes
                                </h1>
                                <p>
                                    Receive instant booking notifications from Airbnb, Booking.com, and Expedia and automatically import booking details like price, dates, guest information and payment information before sending a customized invoice to any of your guests. You may then charge your guests through your payment gateway using the virtual card details already provided.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Easily create owner invoices</h4>
                                            <p>
                                                Quickly generate invoices for multiple owners at a time with options to specify rentals and channels.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Manage invoices</h4>
                                            <p>
                                                View a list of all invoices, filter by status, adjust them as necessary or even download your list.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Visibility options</h4>
                                            <p>
                                                Keep invoices hidden from owners as needed  and choose when to make them visible.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/start_invoicing.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/custom_branding.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Custom Branding
                                </h1>
                                <p>
                                    Tokeet allows you to fully brand your owner invoices with your company logo. Your owners will receive a message letting them know that a new invoice is available.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Professional Touch</h4>
                                            <p>
                                                Put your brand front and center by adding your company logo to your invoices.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Easy Customization</h4>
                                            <p>
                                                With owner invoices, you have the option to add line items along with taxes and discounts.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Seamless Process</h4>
                                            <p>
                                                Owners will receive a message alerting them of the invoice, then they can simply log in and pay online.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Owner Statements <br className="d-none d-lg-block" />
                                    Communicate Your Value
                                </h1>
                                <p>
                                    Give access to your owners to view monthly income statements within Tokeet. Have your bookings and payouts populated automatically and create owner statements easily.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Automatic calculations</h4>
                                            <p>
                                                Bookings and payouts are populated automatically using your payout rules.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Easy management</h4>
                                            <p>
                                                As with owner invoices, you can also view, filter and download your statements.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Save and print</h4>
                                            <p>
                                                You may print your statements or save them to PDF with just one click.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/owner_statements.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 img-holder order-last order-md-first">
                                <img src={require('../../assets/images/solutions/payout_rules.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content">
                                <h1>
                                    Powerful Payout Rules
                                </h1>
                                <p>
                                    Don’t worry about calculating payments for owners or making mistakes. Payout rules automatically calculate a specific payout, or payable amount, for each booking in an owner statement or invoice. Create multiple rules with different restrictions as needed.
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Flexible</h4>
                                            <p>
                                                Create multiple payout rules to apply a different payout amount for different owners, different channels, or different rentals.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Intuitive</h4>
                                            <p>
                                                Tokeet will find a matching rule for each booking depending on the booking's rental, owner, or channel when you are creating an invoice or statement for an owner.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Customizable</h4>
                                            <p>
                                                Payout rules allow you to change how the line items are deducted and whether your starting point will be the booking total or booking formula total.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default OwnerManager;