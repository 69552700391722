import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/casestudies.scss';

function CaseStudiesBnbeyond(){
    PageTitle('Case Study: Tokeet & bnBeyond');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('casestudies-layout')
        return () => {
          document.body.classList.remove('casestudies-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner text-base-banner">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 text-center banner-caption">
                        <h1>
                            Win-Win: How Switching to Tokeet Helped bnBeyond Lower Overhead & Offer More
                        </h1>
                        <p>
                            Time and time again, we hear from users who’ve made the switch to Tokeet after comparing 
                            features and cost to more expensive software options. Looking at cost alone makes Tokeet
                            a shoo-in, but how does it perform against these big brand VRMS contenders?
                            <br /><br />
                            According to <strong>Ryland Nelson</strong>, owner of <strong>bnBeyond</strong>, quite favorably:
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <section className="casestudies-full-content-holder">
            <div className="container">
                <div className="detail-box-holder">
                    <div className="detail-box">
                        <div className="highlighted-green-box">
                            “We use a unique blend of industry leading marketing and booking <br className="d-none d-lg-block" />
                            technologies that allows us to outperform traditional property managers<br className="d-none d-lg-block" />
                            for a fraction of the cost.”
                        </div>
                        <h2>A Good Start</h2>
                        <p>
                            Ryland’s full-service VR management company operates in British Columbia, Canada, and currently employs 20 staff and contractors to cover listings crossing into triple digits. After just 3.5 years in business, that’s quite an accomplishment. 
                            <br /><br />
                            How did he do it? Like many VR managers, bnBeyond began as a solo operation. Ryland found success running his own vacation rental property, and soon found many locals being referred to him to do the same for theirs. From 2016 to 2017, his business grew exponentially, but the influx of clients came with some logistical pains. To continue offering low pricing and sophisticated, full-service management, bnBeyond needed to trim some fat. 
                            <br /><br />
                            In this business, one of the first places you’ll look to save money is in your software choices.
                        </p>
                        <div className="light-box">
                            <img src={require('../../assets/images/case-studies/bnbeyond-logo.png')} alt="" />
                        </div>
                        <h2>Making the Switch</h2>
                        <p>
                            Up until this point, Ryland had been using the same channel manager he’d used for his own property management (Guesty) alongside a rate manager (Pricelabs). While they were able to serve his clientele sufficiently, the inflated usage costs were simply not suitable for a growing management business. 
                            <br /><br />
                            Ryland began trialing various other software options, but found Tokeet’s price points and functionality to be the best fit. Tokeet’s flexible, flat-rate pricing structure allows businesses to save more money as they scale up, compared to Guesty’s fixed 3% take on all their clients’ bookings. 
                            <br /><br />
                            But, while pricing was the initial motivation for Ryland warming to Tokeet, there were a number of offerings that helped seal the deal.
                        </p>
                        <h2>More for Less</h2>
                        <p>
                            While many most channel managers tend to cover very similar features on paper, each has their own way of doing things. Users who’ve switched over to Tokeet from other channel managers may be surprised to find that our features are fully thought out and developed to save you time and effort rather than waste it. 
                            <br /><br />
                            One of the features that Ryland was most impressed with is Tokeet’s handling of Owner Management. It’s an area we’ve paid particular attention to since it’s an integral part of any great VR Management company.
                        </p>
                        <div className="highlighted-green-box">
                            “Price was a consideration, but a major reason was the ability for each of our owners to 
                            have their own individual stripe payment gateway and for us to process credit card transactions 
                            on their behalf (without touching their money we can avoid significant overhead and regulation).”
                        </div>
                        <div className="highlighted-green-box">
                            “The owner center allows us to work seamlessly with the owners we work with 
                            so they can keep their calendars up to date, review reservation data, statements and pay invoices.”
                        </div>
                        <h2>1. Automata – Vacation Rental Automation Tools</h2>
                        <div className="highlighted-green-box">
                            “Through the use of Automata and RateGenie we have continued to automate much of our business 
                            activities that would otherwise have not allowed us to grow to 
                            as big as we have become in such a short time, all the while keeping our overhead low.”
                        </div>
                        <div className="highlighted-green-box">
                            “With Automata we are able to notify owners of events related 
                            to their rental properties, push notifications to our staff of new reservations, new guest messages etc.”
                        </div>
                        <h2>2. Rategenie – Dynamic Rates Management</h2>
                        <div className="highlighted-green-box">
                            “We use RateGenie to set the prices for all of our listings. It has replaced PriceLabs for us. 
                            Our owners/clients don’t really care to know how RateGenie works. They just trust that we are using 
                            the best available software to set competitive rates that maximize revenue from their rental properties.”
                        </div>
                    </div>
                </div>
                <div className="learn-more-about-business">
                    <div className="col-lg-8">
                        <p>
                            Thank you to Ryland Nelson for taking the time to 
                            speak with us and allowing us to learn more about your business!
                        </p>                        
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default CaseStudiesBnbeyond;