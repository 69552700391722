import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/become-a-partner.scss';

function BecomeApartner(){
    PageTitle('Tokeet | Become a Partner');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('become-a-partner-page')
        return () => {
          document.body.classList.remove('become-a-partner-page')
        }
    }, [])
    
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-5 banner-caption">
                            <h1>
                                Become a Partner
                            </h1>
                            <p>
                                Tokeet is proud to offer a suite of products to <br className="d-none d-lg-block" />
                                our customers in the vacation rental industry.
                            </p>
                        </div>
                        <div className="col-md-7 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/become-a-partner-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="benefits-becoming-partner">
                <div className="container">
                    <div className="dark-box-top">
                        <div className="row justify-content-between">
                            <div className="col-md-6 box-l">
                                <h4>
                                    Our mission is to empower anyone to launch, run and 
                                    scale a successful short-term rental management business.
                                </h4>
                            </div>
                            <div className="col-md-6 box-r">
                                <p>
                                    With the help of our partners, we broaden our offerings further, 
                                    making it a one stop shop for property managers. Our partners in turn have the opportunity to expand 
                                    their businesses by offering their services to our growing user-base.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Benefits of becoming<br />
                                    Partner
                                </h1>
                                <p>
                                    At Tokeet, we believe in creating long-term relationships with like-minded businesses to 
                                    bring value to each other. We welcome the opportunity to 
                                    build together and are always happy to discuss a mutually beneficial partnership.
                                </p>
                                <h5>The features available are:</h5>
                                <ul>
                                    <li>Build shared goals for property managers</li>
                                    <li>Increase revenues</li>
                                    <li>Strengthen your product offering</li>
                                    <li>Gain extra marketing support</li>
                                    <li>Boost your brand’s reach</li>
                                    <li>Increase customer satisfaction</li>
                                    <li>Pair with a trusted brand for greater opportunities</li>
                                </ul>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/benefits-becoming-partner.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="how-it-works">
                <div className="container">
                    <div className="row justify-content-center hiw-content-holder">
                        <div className="col-lg-11">
                            <h5>HOW IT WORKS</h5>
                            <h1>
                                To partner with Tokeet is very easy. After reaching out about a partnership, 
                                our team will guide you through the process. 
                                You have our full support from beginning to end!
                            </h1>
                            <div className="row steps-holder">
                                <div className="col-md-6 col-lg-3 steps">
                                    <img src={require('../../assets/images/icons/intro.png')} alt="" />
                                    <br />
                                    <h4>Introductions</h4>
                                    <p>
                                        Get to know Tokeet, what we offer, and how you can integrate.
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-3 steps">
                                    <img src={require('../../assets/images/icons/f-agrement.png')} alt="" />
                                    <br />
                                    <h4>Finalize Agreement</h4>
                                    <p>
                                        Finalize the partnership agreement and execute documents.
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-3 steps">
                                    <img src={require('../../assets/images/icons/development.png')} alt="" />
                                    <br />
                                    <h4>Development</h4>
                                    <p>
                                        Develop and test the integration for the best possible results.
                                    </p>
                                </div>
                                <div className="col-md-6 col-lg-3 steps">
                                    <img src={require('../../assets/images/icons/launch-n-promote.png')} alt="" />
                                    <br />
                                    <h4>Launch & Promote</h4>
                                    <p>
                                        Work together to announce our integration and introduce it to our customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center form-box-holder">
                        <div className="col-lg-10">
                            <p>
                                After becoming familiar with the process and finalizing the agreement, 
                                you will be given access to our Partner API to begin development and testing. 
                                Once it is ready to launch, we will work with you to promote it to our collective customers.
                            </p>
                            <div className="form-box">
                                <form className="box" method="POST"
  action="https://customerioforms.com/forms/submit_action?site_id=16daac4ad3fd9d1a8f83&form_id=01fhwsppvw7m8m6b002j5k5qxc&success_url=https://www.tokeet.com/become-a-partner/success">
                                    <h4>Below form allows you to trigger the partnership procedure.</h4>
                                    <div className="row">
                                        <div className="col-md-6 col-lg-3">
                                            <div className="input-box">
                                                <label>First Name <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="first_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-3">
                                            <div className="input-box">
                                                <label>Last Name <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="last_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <div className="input-box">
                                                <label>Email Address <span className="text-danger">*</span></label>
                                                <input type="email" required className="form-control" name="email" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Company Name <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="company_name" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control" name="phone" />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="input-box">
                                                <label>Address <span className="text-danger">*</span></label>
                                                <textarea className="form-control" name="address" required></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>City <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="city" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Country <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="country" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Zip Code <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="zip" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Website <span className="text-danger">*</span></label>
                                                <input type="text" required className="form-control" name="website" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Partner Type <span className="text-danger">*</span></label>
                                                <div className="radio-box">
                                                    <label htmlFor="business_partner">
                                                        <input id="business_partner" type="radio" defaultChecked name="partner_type"/>
                                                        <div>
                                                            Business Partner
                                                        </div>
                                                    </label>
                                                    <label htmlFor="channel_partner">
                                                        <input id="channel_partner" type="radio" name="partner_type"/>
                                                        <div>
                                                            Channel Partner
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>Do you work with any Tokeet Clients <span className="text-danger">*</span></label>
                                                <div className="radio-box">
                                                <label htmlFor="yes">
                                                    <input id="yes" type="radio" defaultChecked name="work_wt_tokeet"/>
                                                    <div>
                                                        Yes
                                                    </div>
                                                </label>
                                                <label htmlFor="no">
                                                    <input id="no" type="radio" name="work_wt_tokeet"/>
                                                    <div>
                                                        No
                                                    </div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>What is your business speciality <span className="text-danger">*</span></label>
                                                <textarea className="form-control" required name="business_speciality"></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-box">
                                                <label>How long have you been in the business <span className="text-danger">*</span></label>
                                                <textarea className="form-control" required name="business_age"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-holder text-center">
                                        <button type="reset" className="btn btn-reset">Cancel</button>
                                        <button type="submit" className="btn btn-submit">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="dft-content-holder">
                <div className="container">
                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">                            
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/partner-portal.png')} className="img-fluid" alt="" />
                            </div>
                            <div className="col-md-6 content order-first order-md-last">
                                <h1>
                                    Partner Portal
                                </h1>
                                <p>
                                    Partner Portal gives you access of user friendly interface<br className="d-none d-lg-block" /> 
                                    and indepth control of user data.
                                </p>
                                <ul>
                                    <li>
                                        <strong>Partner Signup</strong><br />
                                        Partner Portal gives access to Partners to Signup into portal. 
                                    </li>
                                    <li>
                                        <strong>Create & Configure Apps</strong><br />
                                        Partners can create and configure their apps using portal. 
                                    </li>
                                    <li>
                                        <strong>Logs & Metrics</strong><br />
                                        Partners will be able to debug and monitor their applications. 
                                    </li>
                                </ul>
                                <a href="https://register.tokeet.com/" className="get-started-btn">
                                    <span>Get Started</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="content-box">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-md-6 content">
                                <h1>
                                    Tokeet Partner API
                                </h1>
                                <p>
                                    Our Partner API Hub contains comprehensive guides, API references, 
                                    and other documentation to help you build a solid integration with the Tokeet platform.
                                </p>
                                <ul>
                                    <li>24 / 7 Support</li>
                                    <li>Easy Documentation</li>
                                </ul>
                                <div className="need-help">
                                    <img src={require('../../assets/images/icons/customer-care.png')} alt="" />
                                    <div>
                                        <h4>Need Help?</h4>
                                        <p>Dedicated Support team available to answer your queries related to Partner API</p>
                                    </div>
                                </div>
                                <a href="https://register.tokeet.com/" className="get-started-btn">
                                    <span>Get Started</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                            </div>
                            <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/tokeet-partner-api.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BecomeApartner;