import { React, useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import '../../assets/scss/navbar.scss';

function Navbar(){
    const [scroll, setScroll] = useState(false);
    const location = useLocation();
    const [url, setUrl] = useState(null);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 5);
        });
        setUrl(location.pathname);
    }, [location]); 
    // Notice bar
    // const [ShowTopHasNotichBar, setShowTopHasNotichBar] = useState(true);
    // Getting mobile devices
    const [userIsDesktop, setUserIsDesktop] = useState(true);
    useEffect(() => {
        window.innerWidth > 767 ? setUserIsDesktop(true) : setUserIsDesktop(false);
    }, [userIsDesktop]);
    // Mobile submenus
    const [ShowProductMenus,setShowProductMenus] = useState(false);
    const [ShowSolutionsMenus,setShowSolutionsMenus] = useState(false);
    const [ShowSupportMenus,setShowSupportMenus] = useState(false);

    return (
        <>
        {!userIsDesktop && (
            <>
            {url === "/" && (
            <div className="notice-holder">
                <div className="container position-relative">
                    <div className="flx-box">
                        <img src={require('../../assets/images/icons/rental.png')} alt="" />
                        <p>
                            Are you managing 1 - 3 rentals? We have a great plan for 
                            you, <a href="https://sympl.cm/" target="_blank" rel="noreferrer">Click here</a> to find out more.
                        </p>
                    </div>
                </div>
            </div>
            )}
            </>
        )}
        <nav className={`navbar navbar-expand-lg navbar-main sticky-top ${scroll ? "add-border" : ""}`}>
            {userIsDesktop && (
                <>
                {url === "/" && (
                <div className="notice-holder">
                    <div className="container position-relative">
                        <div className="flx-box">
                            <img src={require('../../assets/images/icons/rental.png')} alt="" />
                            <p>
                                Are you managing 1 - 3 rentals? We have a great plan for 
                                you, <a href="https://sympl.cm/" target="_blank" rel="noreferrer">Click here</a> to find out more.
                            </p>
                            {/* <button type="button" className="btn-x-close" onClick={()=>setShowTopHasNotichBar(false)}>
                                <i className="bi bi-x-lg"></i>
                            </button> */}
                        </div>
                    </div>
                </div>
                )}
                </>
            )}            

            <div className="main-container">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={require('../../assets/images/logo.png')} alt="Tokeet" title="Tokeet" />
                    </Link>
                    {userIsDesktop ? 
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarOffcanvasLg" aria-controls="navbarOffcanvasLg">
                            <i className="bi bi-list"></i>
                        </button>
                    :
                        <button className="navbar-toggler-mobile" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                            <img src={require('../../assets/images/menu.png')} alt="" />
                        </button>
                    }

                    {userIsDesktop ? 

                        <div className="offcanvas offcanvas-end" tabIndex ="-1" id="navbarOffcanvasLg" aria-labelledby="navbarOffcanvasLgLabel">   
                            <button type="button" className="btn btn-offcanvas-close" data-bs-dismiss="offcanvas" aria-label="Close">
                                <i className="bi bi-x-lg"></i>
                            </button>

                            <div className="d-flex align-items-center justify-content-between">
                                <div className="logo-holder">
                                    <Link to="/">
                                        <img src={require('../../assets/images/logo.png')} className="d-none d-lg-block" alt="Tokeet" title="Tokeet" />
                                        <img src={require('../../assets/images/icons/tokeet.png')} className="d-block d-lg-none" alt="Tokeet" title="Tokeet" />
                                    </Link>
                                </div>
                                <div className="nav-holder">
                                    <ul className="navbar-nav">
                                        <li className="dropdown">
                                            <Link to="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Products
                                            </Link>
                                            <div className="dropdown-menu animate slideIn">
                                                <div className="row">
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/tokeet.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                A suite of powerful tools for channel management, rental automation, owner management, and more.
                                                            </p>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://www.useautomata.com/" target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/automata.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                The most powerful automation tool designed for the short-term rental industry.
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://www.usesignature.com/" target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/signature.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                The simple and secure way to get documents signed.
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://store.tokeet.com/details/margins" target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/margins.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                View actionable insights and performance reports.
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://sympl.cm/"  target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/sympl.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                Save time and money managing your rentals, the Sympl way. Built for owners with 1 to 3 rentals.
                                                            </p>
                                                        </a>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://store.tokeet.com/details/owner_center"  target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/owners.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                Manage your owners' access, invoices, payments, and more.
                                                            </p>
                                                        </a>                                                    
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <a href="https://usechecklist.com/"  target="_blank" rel="noreferrer">
                                                            <div className="pdt-logo">
                                                                <img src={require('../../assets/images/products/checklist.png')} alt="" />
                                                            </div>
                                                            <p>
                                                                Automate your property operations and maintenance by allowing Checklist to schedule your service providers.
                                                            </p>
                                                        </a>
                                                    </div>  
                                                </div>
                                            </div>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="#" className="active-solutions" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Solutions
                                            </Link>
                                            <div className="dropdown-menu animate slideIn">
                                                <div className="row">
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/channel-manager" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/channel-management.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Channel Management</h5>
                                                                <p>Seamlessly sync all of your channels.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/automation" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/automation.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Automation</h5>
                                                                <p>Automate your booking workflow with ease.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/owner-manager" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/owner-management.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Owner Management</h5>
                                                                <p>Manage your property owners in a customized owner portal.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/staff-management" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/staff-management.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Staff Management</h5>
                                                                <p>Use checklist to automate your property care with service providers.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/invoice-payments" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/invoice-payments.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Invoice & Payments</h5>
                                                                <p>Send invoices and accept payments online.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/direct-bookings" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/direct-bookings.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Direct Bookings</h5>
                                                                <p>Accept bookings from your own website and avoid booking fees.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/dynamic-pricing" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/dynamic-pricing.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Dynamic Pricing</h5>
                                                                <p>Automatically update your rates daily with the perfect price.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="col-lg-6 menu-container">                        
                                                        <Link to="/solutions/premium-services" className="d-flex">
                                                            <div className="menu-icon">
                                                                <img src={require('../../assets/images/solutions/premium-services.png')} alt="" />
                                                            </div>
                                                            <div className="menu-content">
                                                                <h5>Premium Services</h5>
                                                                <p>Receive a dedicated service account manager for your account.</p>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className={(url === "/pricing" ?"active" : "")}>
                                            <Link to="/pricing">Pricing</Link>
                                        </li>
                                        <li className={(url === "/integrations" ?"active" : "")}>
                                            <Link to="/integrations">Integrations</Link>
                                        </li>
                                        <li>
                                            <a href="https://store.tokeet.com"  target="_blank" rel="noreferrer">App Store</a>
                                        </li>
                                        <li className="dropdown">
                                            <Link to="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Support
                                            </Link>
                                            <div className="dropdown-menu dropdown-menu-xs animate slideIn">
                                                <ul>
                                                    <li>
                                                        <a href="https://help.tokeet.com" target="_blank" rel="noreferrer">Help Center</a>
                                                    </li>
                                                    <li>
                                                        <Link to="/solutions/premium-services">
                                                            Premium Services
                                                        </Link>
                                                    </li>                
                                                    <li>
                                                        <a href="https://www.tokeet.com/community/" target="_blank" rel="noreferrer">Community</a>
                                                    </li>
                                                    <li>
                                                        <a href="https://changes.tokeet.com" target="_blank" rel="noreferrer">Change Log</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        <li className={(url === "/about" ?"active" : "")}>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="btn-holder"> 
                                    <a href="https://app.tokeet.com" className="login-btn">Login</a>
                                    <a href="https://register.tokeet.com">Try for Free</a>
                                </div>
                            </div>                        
                            <div className="d-flex align-items-center justify-content-center secondary-nav-holder">
                                <div className="nav-holder secondary-nav">
                                <ul className="navbar-nav">
                                    <li className={(url === "/solutions/channel-manager" ?"active" : "")}>
                                        <Link to="/solutions/channel-manager">Channel Management</Link>
                                    </li>
                                    <li className={(url === "/solutions/automation" ?"active" : "")}>
                                        <Link to="/solutions/automation">Automation</Link>
                                    </li>
                                    <li className={(url === "/solutions/direct-bookings" ?"active" : "")}>
                                        <Link to="/solutions/direct-bookings">Direct Bookings</Link>
                                    </li>
                                    <li className={(url === "/solutions/invoice-payments" ?"active" : "")}>
                                        <Link to="/solutions/invoice-payments">Invoicing</Link>
                                    </li>
                                    <li className={(url === "/solutions/dynamic-pricing" ?"active" : "")}>
                                        <Link to="/solutions/dynamic-pricing">Dynamic Pricing</Link>
                                    </li>
                                    <li className={(url === "/solutions/staff-management" ?"active" : "")}>
                                        <Link to="/solutions/staff-management">Staff Management</Link>
                                    </li>
                                    <li className={(url === "/solutions/owner-manager" ?"active" : "")}>
                                        <Link to="/solutions/owner-manager">Owner Management</Link>
                                    </li>                                
                                    <li className={(url === "/solutions/premium-services" ?"active" : "")}>
                                        <Link to="/solutions/premium-services">Premium Services</Link>
                                    </li>
                                </ul>
                                </div>
                            </div>
                        </div>

                    :   
                        // Mobile Menus
                        <div className="offcanvas offcanvas-bottom" tabIndex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasBottomLabel">MENU</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" ></button>
                            </div>
                            <div className="offcanvas-body">
                                <ul className="nav-xs">
                                    <li>
                                        <Link to="#" onClick={()=>setShowProductMenus(true)}>Products</Link>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={()=>setShowSolutionsMenus(true)}>Solutions</Link>
                                    </li>
                                    <li data-bs-dismiss="offcanvas">
                                        <Link to="/pricing">Pricing</Link>
                                    </li>
                                    <li data-bs-dismiss="offcanvas">
                                        <Link to="/integrations">Integrations</Link>
                                    </li>
                                    <li>
                                        <a href="https://store.tokeet.com"  target="_blank" rel="noreferrer">App Store</a>
                                    </li>
                                    <li>
                                        <Link to="#" onClick={()=>setShowSupportMenus(true)}>Support</Link>
                                    </li>
                                    <li data-bs-dismiss="offcanvas">
                                        <Link to="/about">About Us</Link>
                                    </li>                                     
                                </ul>
                                <div className="btn-holder"> 
                                    <a href="https://app.tokeet.com" className="login-btn">Login</a>
                                    <a href="https://register.tokeet.com" className="btn">Try for Free</a>
                                </div>
                            </div>
                            {/* Solutions Menus */}
                            {ShowSolutionsMenus && (
                                <div className="submenu-holder">
                                    <div className="menu-header">
                                        <button type="button" className="btn-back" onClick={()=>setShowSolutionsMenus(false)}>
                                            <i className="bi bi-chevron-left"></i>
                                        </button>
                                        <h5 className="menu-title">SOLUTIONS</h5>
                                        <button type="button" className="btn-close" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="menu-body">
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/channel-manager" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/channel-management.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Channel Management</h5>
                                                    <p>Seamlessly sync all of your channels.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/automation" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/automation.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Automation</h5>
                                                    <p>Automate your booking workflow with ease.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/owner-manager" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/owner-management.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Owner Management</h5>
                                                    <p>Manage your property owners in a customized owner portal.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/staff-management" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/staff-management.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Staff Management</h5>
                                                    <p>Use checklist to automate your property care with service providers.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/invoice-payments" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/invoice-payments.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Invoice & Payments</h5>
                                                    <p>Send invoices and accept payments online.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/direct-bookings" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/direct-bookings.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Direct Bookings</h5>
                                                    <p>Accept bookings from your own website and avoid booking fees.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/dynamic-pricing" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/dynamic-pricing.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Dynamic Pricing</h5>
                                                    <p>Automatically update your rates daily with the perfect price.</p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="menu-container" onClick={()=>setShowSolutionsMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/solutions/premium-services" className="d-flex">
                                                <div className="menu-icon">
                                                    <img src={require('../../assets/images/solutions/premium-services.png')} alt="" />
                                                </div>
                                                <div className="menu-content">
                                                    <h5>Premium Services</h5>
                                                    <p>Receive a dedicated service account manager for your account.</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Product Menus */}
                            {ShowProductMenus && (
                                <div className="submenu-holder">
                                    <div className="menu-header">
                                        <button type="button" className="btn-back" onClick={()=>setShowProductMenus(false)}>
                                            <i className="bi bi-chevron-left"></i>
                                        </button>
                                        <h5 className="menu-title">PRODCUTS</h5>
                                        <button type="button" className="btn-close" onClick={()=>setShowProductMenus(false)} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="menu-body">
                                        <div className="menu-container" onClick={()=>setShowProductMenus(false)} data-bs-dismiss="offcanvas">                        
                                            <Link to="/">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/tokeet.png')} alt="" />
                                                </div>
                                                <p>
                                                    A suite of powerful tools for channel management, rental automation, owner management, and more.
                                                </p>
                                            </Link>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="https://www.useautomata.com/" target="_blank" rel="noreferrer">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/automata.png')} alt="" />
                                                </div>
                                                <p>
                                                    The most powerful automation tool designed for the short-term rental industry.
                                                </p>
                                            </a>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="https://www.usesignature.com/"  target="_blank" rel="noreferrer">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/signature.png')} alt="" />
                                                </div>
                                                <p>
                                                    The simple and secure way to get documents signed.
                                                </p>
                                            </a>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="#/">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/margins.png')} alt="" />
                                                </div>
                                                <p>
                                                    View actionable insights and performance reports.
                                                </p>
                                            </a>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="https://sympl.cm/"  target="_blank" rel="noreferrer">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/sympl.png')} alt="" />
                                                </div>
                                                <p>
                                                    Save time and money managing your rentals, the Sympl way. Built for owners with 1 to 3 rentals.
                                                </p>
                                            </a>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="#/">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/owners.png')} alt="" />
                                                </div>
                                                <p>
                                                    Manage your owners' access, invoices, payments, and more.
                                                </p>
                                            </a>
                                        </div>
                                        <div className="menu-container">                        
                                            <a href="https://usechecklist.com/"  target="_blank" rel="noreferrer">
                                                <div className="pdt-logo">
                                                    <img src={require('../../assets/images/products/checklist.png')} alt="" />
                                                </div>
                                                <p>
                                                    Automate your property operations and maintenance by allowing Checklist to schedule your service providers.
                                                </p>
                                            </a>
                                        </div>  
                                    </div>
                                </div>
                            )}

                            {/* Support Menus */}
                            {ShowSupportMenus && (
                                <div className="submenu-holder">
                                    <div className="menu-header">
                                        <button type="button" className="btn-back" onClick={()=>setShowSupportMenus(false)}>
                                            <i className="bi bi-chevron-left"></i>
                                        </button>
                                        <h5 className="menu-title">Support</h5>
                                        <button type="button" className="btn-close" onClick={()=>setShowSupportMenus(false)} data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className="menu-body">
                                        <ul>
                                            <li>
                                                <a href="https://help.tokeet.com" target="_blank" rel="noreferrer">Help Center</a>
                                            </li>
                                            <li data-bs-dismiss="offcanvas">
                                                <Link to="/solutions/premium-services">
                                                    Premium Services
                                                </Link>
                                            </li>                
                                            <li>
                                                <a href="https://www.tokeet.com/community/" target="_blank" rel="noreferrer">Community</a>
                                            </li>
                                            <li>
                                                <a href="https://changes.tokeet.com" target="_blank" rel="noreferrer">Change Log</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}

                        </div>
                    }
                </div>
            </div>
        </nav>
        </>
    );
}

export default Navbar;