import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function PremiumServices(){
    PageTitle('Tokeet | Premium Services');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 banner-caption">
                            <h1>
                                Premium Services
                            </h1>
                            <p>
                                Enjoy personalized assistance with your Tokeet account.
                            </p>
                            <p className="btn-holder">
                                <a href="https://register.tokeet.com/" className="btn btn-start-trial">
                                    Start Free Trial
                                </a>
                                <a href="https://www.youtube.com/embed/iB079WoO9uQ" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                            <img src={require('../../assets/images/solutions/channel-partners-2.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/premium-services-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="temp-content-holder bg-white">
                <div className="container">
                    <div className="dark-box-top">
                        <div className="title-holder">            
                            <h4>PERSONAL SMILE</h4>            
                            <h1>
                                Get one-on-one support <br className="d-none d-lg-block" />
                                when you need it
                            </h1>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-md-6 col-lg-6 box">
                                <p>
                                    Our Customer Support team helps clients via online chat, which is great for giving quick advice and sharing the right documentation. But what about clients that need more than that? Some businesses just work better with relationships - Customer Success professionals you know personally who know your business and are there for help when you call… or are calling you! 
                                    <br /><br />
                                    Tokeet’s Premium Services offering fills that need. With a subscription based on your unit count you will have access to services such as:
                                </p>
                            </div>
                            <div className="col-md-6 col-lg-5 box">
                                <div className="flx-bx">
                                    <div className="flx-icon">
                                        <img src={require('../../assets/images/solutions/account_manager.png')} alt="" />
                                    </div>
                                    <div>
                                        <h4>Dedicated full service Account Manager with Tokeet technical skills.</h4>
                                    </div>
                                </div>
                                <div className="flx-bx">
                                    <div className="flx-icon">
                                        <img src={require('../../assets/images/solutions/virtual_training.png')} alt="" />
                                    </div>
                                    <div>
                                        <h4>Live virtual training with your data.</h4>
                                    </div>
                                </div>
                                <div className="flx-bx">
                                    <div className="flx-icon">
                                        <img src={require('../../assets/images/solutions/early_access.png')} alt="" />
                                    </div>
                                    <div>
                                        <h4>Early access to features in beta.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-11 col-lg-10 content-header-text">
                            <h2>
                                Don’t want to worry about setting up your account? <br className="d-none d-lg-block" />  
                                You’ll get <span>actual configuration</span> support as well as <span>front desk services</span> to take booking requests and questions from your guests.
                            </h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PremiumServices;