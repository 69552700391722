import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function StaffManagement(){
    PageTitle('Tokeet | Staff Management for Short-Term Rental Managers');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 banner-caption">
                            <h1>
                                Property Care <br className="d-none d-lg-block" /> 
                                Made Easy.
                            </h1>
                            <p>
                                Save the time and hassle taken to schedule your property operations and maintenance by allowing Checklist to schedule your service providers. 
                            </p>
                            <p className="btn-holder">
                                <a href="https://register.usechecklist.com/account" className="btn btn-start-trial">
                                    Start Free Trial
                                </a>
                                <a href="https://youtu.be/LGYF2dFjZi0" data-lity className="btn watch-video-btn">
                                    <span>Watch Video</span>
                                    <i className="bi bi-play-circle-fill"></i>
                                </a>
                            </p>
                        </div>
                        <div className="col-md-6 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/staff-management-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="covid-resources-holder">
                <div className="container">
                    <div className="covid-resources">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-7 col-xl-6 title-holder">            
                            <h4>LET US HELP.</h4>            
                            <h1>
                                View COVID-19 resources
                            </h1>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 content-box-holder">
                            <div className="content-box problem">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/solutions/the_problem.png')}  alt="" />
                                </div>
                                <h2>Always Putting Out Fires</h2>
                                <ul>
                                    <li>
                                        Too much time spent coordinating and communicating with service providers.
                                    </li>
                                    <li>
                                        Confusing ‘To-Do’ lists, or verbal instructions that are quickly forgotten.
                                    </li>
                                    <li>
                                        Service providers forgetting to complete specific tasks.
                                    </li>
                                    <li>
                                        Too many tasks not being completed correctly.
                                    </li>
                                    <li>
                                        No way to schedule and track all jobs needed- cleaning, maintenance, gardening, etc.
                                    </li>
                                    <li>
                                        Trouble finding which service provider is available for a job.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 content-box-holder">
                            <div className="content-box solution">
                                <div className="img-holder">
                                    <img src={require('../../assets/images/solutions/the_solutions.png')}  alt="" />
                                </div>
                                <h2>Property Care Made Easy</h2>
                                <ul>
                                    <li>
                                        Automated and manual job creation & cancellation.
                                    </li>
                                    <li>
                                        Visual checklists to ensure tasks are completed as intended.
                                    </li>
                                    <li>
                                        Job and task reminders for service providers.
                                    </li>
                                    <li>
                                        Track job progress in real-time, and ensure quality by reviewing task images or incident reports submitted by service providers.
                                    </li>
                                    <li>
                                        Add unlimited service providers from any service industry- housekeeping, gardening, maintenance and so much more.
                                    </li>
                                    <li>
                                        Automatically send jobs to another service provider, if your prefered service provider is unavailable.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="what-we-offer">
                <div className="container">
                    <div className="title-holder">
                        <h4>WHAT WE OFFER</h4>
                        <h1>
                            Our operations and services platform automates<br className="d-none d-lg-block" /> the messiness of property care so you can focus<br className="d-none d-lg-block" /> on what really matters.
                        </h1>
                    </div>
                    <div className="flex-content-box">
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/visual_checklists.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Visual Checklists</h4>
                                <p>
                                    Create detailed to-do lists for your service providers by adding text and images.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/automation_icon.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Automation</h4>
                                <p>
                                    Set up assignment rules to automatically create and send jobs to selected Service Providers.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/incident_reports.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Incident Reports</h4>
                                <p>
                                    Review the details of incident reports and any accompanying images submitted by Service Providers.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/job_assignments.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Manual Job Assignments</h4>
                                <p>
                                    Create jobs manually at any time, for any property.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/real_time_updates.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Real time Updates</h4>
                                <p>
                                    Receive progress updates for jobs in real time.
                                </p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/solutions/payments_icon.png')}  alt="" />
                            </div>
                            <div className="content-holder">
                                <h4>Payments</h4>
                                <p>
                                    Pay your service provider invoices using a debit or credit card.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="temp-content-holder">
                    <div className="container">
                        <div className="key-features">
                            <h1>Our Key Features</h1>
                            <p>
                                You are minutes away from putting your property<br className="d-none d-md-block" />
                                management on autopilot.
                            </p>
                        </div>

                        <div className="content-box">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-6 content">
                                    <h1>
                                        Automate Job <br className="d-none d-lg-block" />
                                        Assignments
                                    </h1>
                                    <p>
                                        Spend less time scheduling service providers by setting up an assignment rule.
                                    </p>
                                    <ul>
                                        <li>
                                            <div className="icon-holder">
                                                <img src={require('../../assets/images/solutions/flexibility.png')}  alt="" />
                                            </div>
                                            <div className="li-content">
                                                <h4>Service Providers</h4>
                                                <p>
                                                    Assignment Rules will automatically create and send jobs to your selected Service Providers.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <img src={require('../../assets/images/solutions/flexible_pricing.png')}  alt="" />
                                            </div>
                                            <div className="li-content">
                                                <h4>First come services</h4>
                                                <p>
                                                    If your preference is to give the job to the first service provider who can accept it then you can select the first come first serve strategy.
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon-holder">
                                                <img src={require('../../assets/images/solutions/innovative.png')}  alt="" />
                                            </div>
                                            <div className="li-content">
                                                <h4>Round-Robin style</h4>
                                                <p>
                                                    You may have a desired preference of who is your top pick for the job, in this case, you can choose the round-robin style selection which uses a priority list strategy.
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                    <a href="https://register.usechecklist.com/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                                <div className="col-md-6 img-holder">
                                <img src={require('../../assets/images/solutions/automate_job_assignments.png')} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>

                        <div className="content-box">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-6 img-holder order-last order-md-first">
                                    <img src={require('../../assets/images/solutions/pay_service_provider.png')} className="img-fluid" alt="" />
                                </div>
                                <div className="col-md-6 content">
                                    <h1>
                                        Pay Service <br className="d-none d-lg-block" />
                                        Providers
                                    </h1>
                                    <p>
                                        Easily manage the entire job process,<br className="d-none d-lg-block" /> 
                                        from scheduling to payments.                                         
                                        <br /><br />
                                        Pay your service provider invoices using your<br className="d-none d-lg-block" /> debit or credit card in just a few clicks.
                                        <br /><br />
                                    </p>
                                    <a href="https://register.usechecklist.com/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                        <span>Get Started</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="content-box">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-md-6 content">
                                <h1>
                                    Review Incident <br className="d-none d-lg-block" />
                                    Reports
                                </h1>
                                <p>
                                    Take quick action on incidents with access to incident reports 
                                    details and accompanying images submitted by Service Providers. 
                                </p>
                                <ul>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexibility.png')}  alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Incident Reports</h4>
                                            <p>
                                                Get proper reporting and documentation of any unusual occurrence with incident reports.
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon-holder">
                                            <img src={require('../../assets/images/solutions/flexible_pricing.png')}  alt="" />
                                        </div>
                                        <div className="li-content">
                                            <h4>Service Provider</h4>
                                            <p>
                                                Be alerted quickly about incidents. Reports are instantly available to Clients once submitted by the Service Provider.
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <a href="https://register.usechecklist.com/account" className="try-free-btn" target="_blank" rel="noreferrer">
                                    <span>Get Started</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a>
                                </div>
                                <div className="col-md-6 img-holder">
                                    <img src={require('../../assets/images/solutions/review_incident_report.png')} className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>

                    </div>
            </section>

            <section className="how-it-works">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <h1>How it works for</h1>
                                <div className="tab-holder">
                                    <ul className="nav nav-tabs" role="tablist">
                                        <li className="nav-item" role="presentation">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#clients-tab-pane" type="button" role="tab" aria-selected="true">Clients</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#service_provider-tab-pane" type="button" role="tab" aria-selected="false">Service Provider</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="clients-tab-pane" role="tabpanel" tabIndex="0">
                                            <div className="img-holder">
                                                <img src={require('../../assets/images/solutions/clients_hiw.png')} className="img-fluid" alt="" />
                                            </div>
                                            <div className="btn-holder">
                                                <a href="https://usechecklist.com/how-it-works.html" className="btn" target="_blank" rel="noreferrer">
                                                    <span>Learn More</span>
                                                    <i className="bi bi-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="service_provider-tab-pane" role="tabpanel" tabIndex="0">
                                            <div className="img-holder">
                                                <img src={require('../../assets/images/solutions/service_provider_hiw.png')} className="img-fluid" alt="" />
                                            </div>
                                            <div className="btn-holder">
                                                <a href="https://usechecklist.com/how-it-works.html" className="btn" target="_blank" rel="noreferrer">
                                                    <span>Learn More</span>
                                                    <i className="bi bi-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section className="stay-connected">
                    <div className="container">
                        <div className="row align-items-md-end align-items-xl-center">
                            <div className="col-md-8 col-lg-9">
                                <h4>STAY CONNECTED</h4>
                                <h1>Available for your<br /> Smartphone</h1>
                                <p>
                                    Stay connected- Checklist Mobile app allows you to manage jobs and<br className="d-none d-lg-block" />
                                    scheduling when you’re on-the-go. Available for free for iOS and Android.
                                </p>
                                <div className="row">
                                    <div className="col-lg-6 flx-box-holder">
                                        <div className="flx-box">
                                            <div className="icon-holder property_managers">
                                                <img src={require('../../assets/images/solutions/property_managers_icon.png')}  alt="" />
                                            </div>
                                            <div className="cnt-holder">
                                                <h5>For Property Managers</h5>
                                                <p>Download it from Play Store or App Store</p>
                                                <div className="btn-holder">
                                                    <a href="https://play.google.com/store/apps/details?id=com.tokeet.checklistpm" target="_blank" rel="noreferrer">
                                                        <img src={require('../../assets/images/solutions/google_play.png')}  alt="" />
                                                    </a>
                                                    <a href="https://apps.apple.com/app/checklist-pm/id1526165581" target="_blank" rel="noreferrer">
                                                        <img src={require('../../assets/images/solutions/app_store.png')}  alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 flx-box-holder">
                                        <div className="flx-box">
                                            <div className="icon-holder service_providers">
                                                <img src={require('../../assets/images/solutions/service_providers_icon.png')}  alt="" />
                                            </div>
                                            <div className="cnt-holder">
                                                <h5>For Service providers</h5>
                                                <p>Download it from Play Store or App Store</p>
                                                <div className="btn-holder">
                                                    <a href="https://play.google.com/store/apps/details?id=com.tokeet.checklist" target="_blank" rel="noreferrer">
                                                        <img src={require('../../assets/images/solutions/google_play.png')}  alt="" />
                                                    </a>
                                                    <a href="https://apps.apple.com/app/checklist-staff/id1526165311" target="_blank" rel="noreferrer">
                                                        <img src={require('../../assets/images/solutions/app_store.png')}  alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3 img-holder">
                                <img src={require('../../assets/images/solutions/mobile_app.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
            </section>
        </>
    );
}

export default StaffManagement;