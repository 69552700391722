import { React, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";
import $ from 'jquery';
import { PopupWidget } from "react-calendly";

import '../../assets/scss/pricing.scss';

function Pricing(){
    PageTitle('Tokeet | Flexible Pricing for Superior Channel Management Software');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('pricingpage')
        return () => {
          document.body.classList.remove('pricingpage')
        }
    }, [])   

    // For plus and minus buttons
    let [num, setNum]= useState(10);
    let incNum =()=>{
        setNum(Number(num)+1);
    };
    let decNum = () => {
        if(num>1)
        {
        setNum(num - 1);
        }
    }
    let handleChange = (e)=>{
        let targetValue = e.target.value;
        e.target.value = 0 || e.target.value === "" ? (targetValue=1) : (targetValue = e.target.value)
        setNum(targetValue);
    }

    // Loading....
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }

    $(document).ready(function(){   
        $(".currency-list li a").click(function(){
            $(this).parents(".dropdown").find('.btn').html($(this).text() + ' <i class="bi bi-chevron-down d-icon"></i>');
            $(this).parents(".dropdown").find('.btn').val($(this).data('value'));
        });  

        $('.range-slider').on('change input', function () {
            var val = ($(this).val() - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min'));
    
            $(this).css('background-image',
                        '-webkit-gradient(linear, left top, right top, '
                        + 'color-stop(' + val + ', #17A52F), '
                        + 'color-stop(' + val + ', #444652)'
                        + ')'
                        );
        });        
        $('.range-slider').trigger('change');

        // Pricing
        var prices = null;
        var monthlyPlanId = 'tokeet_v4';
        var yearlyPlanId = 'tokeet_yr_v4';

        var DEFAULT_PLAN_INTERVAL = 'month';
        var BASE_CURRENCY = 'usd';
        var currencySymbolMapping = {
            'usd': '$',
            'eur': '€',
            'gbp': '£',
        };
    
        var $rentalCount = $('#rental-quantity-input');
    
        // Load tokeet Plans
        loadPrices();

        function loadPrices() {
            $.ajax({
                dataType: "json",
                cache: true,
                url: 'https://api.tokeet.com/plans/price/tokeet',
                success: $.proxy(function (data) {
                    prices = data;
                    $rentalCount.trigger('change');
                }, this)
            });
        }
    
        function getExchangeRate(from, to, cb) {
            var url = 'https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/' + from + '/' + to + '.json';
            $.ajax({
                dataType: "json",
                cache: true,
                url: url,
                success: $.proxy(function (data) {
                    cb(data[to]);
                }, this)
            });
        }
    
        $rentalCount.change(function () {
            refreshUI();
        });
    
        $(".plan-interval").on('change', "input[type='radio']", function (e) {
            refreshUI();
        });
    
        var selectedCurrency = $('.btn-currency').attr('value') || BASE_CURRENCY;
        var currencyExchangeRate = 1;
        getExchangeRate(BASE_CURRENCY, selectedCurrency, function (rate) {
            currencyExchangeRate = rate;
            refreshUI();
        });
        $('.dropdown-currency-list').on('click', function(e) {
            selectedCurrency = $('.btn-currency', this).attr('value') || BASE_CURRENCY;
            if (selectedCurrency === BASE_CURRENCY) {
                currencyExchangeRate = 1;
                refreshUI();
                return;
            }
    
            getExchangeRate(BASE_CURRENCY, selectedCurrency, function (rate) {
                currencyExchangeRate = rate;
                refreshUI();
            });
        });
    
        function getTokeetRentalsPrice(count, prices) {
            return prices.reduce(
                (acc, item) => {
                    if (!acc.count) {
                        return acc;
                    }
                    let span = 0;
                    if (item.min && item.max) {
                        span = item.max - item.min + 1;
                        const c = acc.count > span ? span : acc.count;
                        return { count: acc.count - c, total: acc.total + c * item.price };
                    }
    
                    if (item.min) {
                        return { count: 0, total: acc.total + acc.count * item.price };
                    }
    
                    return acc;
                },
                { count, total: 0 },
            );
        }
    
        function formatNumber(value) {
            value = +value || 0;
    
            if (!(typeof window['Intl'] || typeof window['Intl']['NumberFormat'])) return value.toFixed(2);
    
            return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
        }
    
        function refreshUI() {
            if (!prices) return;
    
            var currency = selectedCurrency || BASE_CURRENCY;
            var currencySymbol = currencySymbolMapping[currency];
            var rate = currencyExchangeRate || 1;
            var rentals = $rentalCount.val() * 1;
            var interval = $('input[name=planInterval]:checked').val();
            var monthly = interval === 'month';
    
            var planPrices = prices[monthly ? monthlyPlanId : yearlyPlanId];
    
            const tokeetPrices = getTokeetPrices(planPrices, monthly)
            let planPrice = getTokeetRentalsPrice(rentals, tokeetPrices).total
    
            planPrice = Math.round(planPrice * rate * 100) / 100;
            planPrice = formatNumber(planPrice);
    
            // $('.plan-name').html(plan.name);
            // $('.year-price-discount').html('20%');
            // $('.plan-user-count').html(plan.users);
            $('.prices-table-wrapper').html(buildPricesTable(planPrices, monthly));
            $('.plan-rental-count').html(rentals);
            $('.plan-price').html(currencySymbol + planPrice);
            $('.plan-price-interval').html(monthly ? 'monthly' : 'yearly');
        }
    
        function getTokeetPrices(planPrices, monthly) {
            planPrices = (planPrices).sort(function (a, b) {
                var upToA = a.upTo || Number.MAX_SAFE_INTEGER
                var upToB = b.upTo || Number.MAX_SAFE_INTEGER
                return upToA - upToB;
            });
            var data = planPrices.reduce(function (acc, price) {
                var label = `${(acc.last?.upTo || 0) + 1} - ${price.upTo}`
                var amount = ((price.amount / (monthly ? 1 : 12)) / 100).toFixed(2)
          
                var item = {
                  rangeLabel: price.upTo ? label : `${acc.last.upTo + 1}+`,
                  min: !acc.last ? 1 : acc.last.upTo + 1,
                  max: price.upTo,
                  price: amount,
                }
                acc.prices.push(item)
          
                return { prices: acc.prices, last: price }
              },
              { prices: [], last: null }
            )
          
            return data.prices
        }
          
        function buildPricesTable(planPrices, monthly) {
        var priceItems = getTokeetPrices(planPrices, monthly);

        return `<table class="table">
            <tr class="table-header thead-light">
                <th>Rentals</th>
                <th>Price Per Rental</th>
            </tr>
            ${priceItems.map(function (item) {
                return `<tr><td>${item.rangeLabel}</td><td>$${item.price} <div class="text-muted">For each rental</div></td></tr>`
            }).join('')}
        </table>`
        }
        // Init
        $("input[type='radio'][value='" + DEFAULT_PLAN_INTERVAL + "']").attr("checked", true);
    });

    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 col-lg-5 banner-caption">
                            <h1>
                                Scalable Pricing<br />
                                for Everyone
                            </h1>
                            <p>
                                Sign up in minutes and upgrade at any time.<br />
                                Low fees, no hassles.
                            </p>
                        </div>
                        <div className="col-md-6 col-lg-5 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/pricing-graphics.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="pricing-content-holder">
                <div className="container">
                    <div className="row justify-content-around rental-selection-box">
                        <div className="col-12 col-sm-auto">
                            <div className="step">Step 1</div>
                            <h4>Total rentals you manage:</h4>
                            <div className="rental-quantity">
                                <div className="rental_value">
                                    <i className="bi bi-dash minus-btn" onClick={decNum}></i>
                                    <input id="rental-quantity-input" className="qty" type="number" min={1} value={num} onChange={handleChange} />
                                    <i className="bi bi-plus plus-btn" onClick={incNum}></i>
                                </div>
                                <input type="range" min={1} max={201} step={1} value={num} onChange={handleChange} className="range-slider" />
                            </div>
                        </div>
                        <div className="col-12 col-sm-auto">
                            <div className="step">Step 2</div>
                            <h4>Select your plan:</h4>
                            <div className="radio-box-holder plan-interval">
                                <div className="radio-box">
                                    <label>
                                        <input type="radio" name="planInterval" value="month"/>
                                        <div>
                                        <i className="bi bi-check-lg"></i>
                                            Monthly
                                        </div>
                                    </label>
                                </div>
                                <div className="radio-box">
                                    <label>
                                        <input type="radio" name="planInterval" value="year" />
                                        <div>
                                        <i className="bi bi-check-lg"></i>
                                            Yearly - <span className="year-price-discount">20%</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-auto">
                            <div className="step">Step 3</div>
                            <h4>Select your currency:</h4>
                            <div className="dropdown dropdown-currency-list">
                                <button className="btn btn-currency" type="button" value="usd" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    $ - Dollar
                                    <i className="bi-chevron-down d-icon"></i>
                                </button>
                                <div className="dropdown-menu currency-list">
                                    <li><a href="#/" data-value="usd">$ - Dollar</a></li>
                                    <li><a href="#/" data-value="eur">€ - Euro</a></li>
                                    <li><a href="#/" data-value="gbp">£ - Pound</a></li>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-11 col-sm-10 col-md-8 col-lg-6">
                            <div className="pricing-box">
                                <div className="box">
                                    <h1 className="plan-price">
                                        $134.90
                                    </h1>
                                    <p>with <span className="plan-price-interval">monthly</span> payments</p>
                                </div>
                                <div className="box">
                                    <div>
                                    <h1 className="plan-rental-count">
                                        10
                                    </h1>
                                    <p>max rentals</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center"> 
                        <div className="col-md-7 col-lg-8 col-xl-6 content-holder">
                            <div className="features-box">
                            <h4>Features</h4>
                            <ul className="feature-list plan-feature-list">
                                <li>Reservation Manager</li>
                                <li>Channel Manager</li>
                                <li>Mobile App</li>
                                <li>Website Builder</li>
                                <li>Payment Gateways</li>
                                <li>Integrations</li>
                                <li>Owners Manager</li>
                                <li>Custom Branding</li>
                                <li>Unlimited Users</li>
                            </ul>
                            <a href="https://register.tokeet.com" className="btn btn-start-trial" target="_blank" rel="noreferrer">                
                                <span>Start Free Trial</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>              
                            <p className="txt-info">
                                By clicking Start Free Trial you agree to the <Link to="/terms-of-service" target="_blank">Terms of Service</Link> and <Link to="/privacy-policy" target="_blank">Privacy Policy</Link>.
                            </p>
                            </div>
                        </div>                                               
                        <div className="col-md-5 col-lg-4 col-xl-4 content-holder">
                            <div className="prices-table-wrapper">
                                <div></div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-10 content-holder">
                            <div className="free-trail-box">
                                <h4>15 Days Free Trial</h4>
                                <div className="flx-box">                                    
                                    <ul className="feature-list">
                                        <li>No credit card required.</li>
                                        <li>Try with no restrictions.</li>
                                        <li>No booking or usage fees.</li>
                                        <li>We have a FREE PLAN.</li>
                                    </ul>
                                </div>
                                <div className="btn-flx-box">
                                    <a href="https://calendly.com/tokeet-success/demo" className="btn btn-book-demo" target="_blank" rel="noreferrer">
                                        <span>Book a Demo</span>
                                        <i className="bi bi-arrow-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PopupWidget
                url="https://calendly.com/d/dtp-mmw-h2t"
                rootElement={document.getElementById("root")}
                primary_color="00a99d" 
                text="Contact Sales ☎"
                color="#00a99d" 
                textColor="#ffffff" 
                pageSettings={{
                    primaryColor: '00a99d'                    
                }}
            />
        </>
    );
}

export default Pricing;