import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import { Link } from 'react-router-dom';
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/casestudies.scss';

function CaseStudies(){
    PageTitle('Tokeet | Case Studies in Short-Term Rental Management');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('casestudies-layout')
        return () => {
          document.body.classList.remove('casestudies-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-5 banner-caption">
                        <h1>
                            Tokeet <br />
                            Case Studies.
                        </h1>
                        <p>
                            See what other users are saying about Tokeet <br className="d-none d-lg-block" />
                            and Tokeet Mobile around the web.
                        </p>
                    </div>
                    <div className="col-md-7 banner-holder">
                        <img src={require('../../assets/images/case-studies/case-studies.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        
        <section className="casestudies-content-holder">
            <div className="container">
                <div className="banner-holder">
                    <img src={require('../../assets/images/case-studies/banner-lg.png')} alt="" />
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4 content-box">
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/case-studies/city-concierge.png')} alt="" />
                            </div>
                            <h4>The City Concierge’s Rapid Expansion</h4>
                            <p>
                                The City Concierge is fast approaching that landmark after just 4 years of operation. The City Concierge is a full-service management agency operating across six cities …
                            </p>
                            {/* <p className="auth">
                                By Kendra Cherry &nbsp;&nbsp;|&nbsp;&nbsp; Updated on July 22, 2022
                            </p> */}
                            <Link to="/case-studies/city-concierge" className="btn-full-case-story">
                                <span>FULL CASE STUDY</span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 content-box">
                        <div className="box">
                            <div className="img-holder">
                                <img src={require('../../assets/images/case-studies/bnbeyond.png')} alt="" />
                            </div>
                            <h4>Win-Win: How Switching to Tokeet Helped bnBeyond Lower Overhead & Offer More</h4>
                            <p>
                                Time and time again, we hear from users who’ve made the switch to Tokeet after comparing features and cost to more expensive software options. Looking at cost …
                            </p>
                            {/* <p className="auth">
                                By Kendra Cherry &nbsp;&nbsp;|&nbsp;&nbsp; Updated on July 22, 2022
                            </p> */}
                            <Link to="/case-studies/bnbeyond" className="btn-full-case-story">
                                <span>FULL CASE STUDY</span>
                                <i className="bi bi-arrow-right"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default CaseStudies;