import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/affiliate-faq.scss';

function AffiliateFaq(){
    PageTitle('Tokeet | Vacation Rental Owner Management Tools');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('affiliate-faq-layout')
        return () => {
          document.body.classList.remove('affiliate-faq-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8 text-center banner-caption">
                        <h1>
                            Frequently Asked Questions<br />
                            Tokeet Affiliate Program
                        </h1>
                    </div>
                </div>
            </div>
        </section>
        <section className="content-holder">
            <div className="container">
                <div className="container-box">
                    <div className="question-box">
                        <div className="qstn-nb">Q 1</div>
                        <div>
                            <h4>How much commission do I receive for each referral? How long do the commissions last?</h4>
                            <p>
                                For each new paid Tokeet subscriber you will receive a 20% commission of their subscription fee for the life of their subscription, for up to 12 months.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 2</div>
                        <div>
                            <h4>Do I receive a commission for each product my referrals use?</h4>
                            <p>
                                Each affiliate will only be entitled to a 20% of the Tokeet subscription and not any of the add ons.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 3</div>
                        <div>
                            <h4>How soon will I receive my commission for a new referral?</h4>
                            <p>
                                You will receive your first commission payment after the Tokeet subscriber has been signed for at least 60 days.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 4</div>
                        <div>
                            <h4>Must my referral stay as a Tokeet user for a specific amount of time?</h4>
                            <p>
                                Your referral must remain with Tokeet for at least 30 days?
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 5</div>
                        <div>
                            <h4>How would I know how well I am doing?</h4>
                            <p>
                                All updates concerning your progress will be viewable in your affiliate Dashboard. You may login to your affiliate dashboard at any time to review your performance and payout information.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 6</div>
                        <div>
                            <h4>How do I get paid?</h4>
                            <p>
                                For now, all payments are made via bank transfer.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 7</div>
                        <div>
                            <h4>Is the program available to people around the world?</h4>
                            <p>
                                The Affiliate program will be available in most geographical locations throughout the world.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 8</div>
                        <div>
                            <h4>Is there a minimum payout amount?</h4>
                            <p>
                                The minimum payout to each Affiliate will be 30 USD.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 9</div>
                        <div>
                            <h4>Where and how may I promote Tokeet?</h4>
                            <p>
                                You will be provided with your own affiliate link that you can post on your own site, social media platforms, blogs or other affiliate promotion sites. You are restricted from running PPC advertising on Facebook and Google.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 10</div>
                        <div>
                            <h4>Can I use my own affiliate link for my own subscription?</h4>
                            <p>
                                Sure, you can sign up with Tokeet using your own link but you will not be entitled to any commission for signing up yourself.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 11</div>
                        <div>
                            <h4>Must I be a Tokeet user to become an affiliate?</h4>
                            <p>
                                No, you do not need to be a Tokeet user to make money as a Tokeet Affiliate.
                            </p>
                        </div>
                    </div>
                    <div className="question-box">
                        <div className="qstn-nb">Q 12</div>
                        <div>
                            <h4>I have a potential subscriber who is interested in signing up for a yearly plan. Am I still entitled to 20% of that yearly subscription amount?</h4>
                            <p>
                                Sure, you will still be entitled to your 20% commission on that yearly subscription fee.
                            </p>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
        
        </>
    );
}

export default AffiliateFaq;