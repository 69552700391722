import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function Automation(){
    PageTitle('Tokeet | Automation for Short-Term Rental Managers');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6 banner-caption">
                        <h1>
                            Save time and <br className="d-none d-lg-block" /> 
                            money with Automata.
                        </h1>
                        <p>
                            You can focus on growing your rental business while Automata executes all 
                            the tasks related to a guest booking, 
                            from alerting staff of upcoming arrivals or greeting your guests, to sending contracts. 
                        </p>
                        <p className="btn-holder">
                            <a href="https://register.tokeet.com/" className="btn btn-start-trial">
                                Start Free Trial
                            </a>
                            <small className="btn-info-text-absolute">Tokeet account required.</small>
                            <a href="https://youtu.be/hKJaxDUlH9M" data-lity className="btn watch-video-btn">
                                <span>Watch Video</span>
                                <i className="bi bi-play-circle-fill"></i>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-6 banner-holder order-first order-md-last">
                        <img src={require('../../assets/images/automation-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="temp-content-holder">
            <div className="container">

                <div className="dark-box-top">
                    <div className="title-holder mb-0">            
                        <h4>TOKEET SUITE</h4>            
                        <h1>
                            Create a flawless guest experience and become a vacation rental super host while saving more time. Automata is part of the Tokeet suite.
                        </h1>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                        <h1>
                            Impress guests & <br className="d-none d-lg-block" />
                            increase your ratings
                        </h1>
                        <p>
                            Create an exceptional guest experience by delivering the right information to your guests at the right time, in their own language.
                        </p>
                        <ul>
                            <li>
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                </div>
                                <div className="li-content">
                                    <h4>Hand-in-Hand assistance</h4>
                                    <p>
                                        Keep your guests and staff informed and empowered from inquiry to checkout by providing hand-in-hand assistance at every stage of the guest journey, automatically.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                </div>
                                <div className="li-content">
                                    <h4>Communicate with your guests</h4>
                                    <p>
                                    Communicate with your guests or staff via email, SMS, or push notification, and keep everyone informed of any changes in the booking details.
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="icon-holder">
                                    <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                </div>
                                <div className="li-content">
                                    <h4>Tokeet message thread</h4>
                                    <p>
                                        When a guest replies back to your SMS or email, their message is automatically saved in their Tokeet message thread.
                                    </p>
                                </div>
                            </li>
                        </ul>
                        <a href="https://register.tokeet.com/" className="try-free-btn">
                            <span>Try for Free</span>
                            <i className="bi bi-arrow-right"></i>
                        </a>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/staff_communication.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 img-holder order-last order-md-first">
                            <img src={require('../../assets/images/solutions/automated_integrated.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 content">
                            <h1>
                                Quickly create hassle-free automation
                            </h1>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Simple Setup</h4>
                                        <p>
                                            With sample templates, get all your automated messaging ready in a snap.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Get Organized</h4>
                                        <p>
                                            View all of your rental-specific messaging in flow or 
                                            list form, so you always know what messages will be sent when.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Easy Scalability</h4>
                                        <p>
                                            With the ability to duplicate a workflow, scaling 
                                            your automated messaging across hundreds of rentals has never been easier.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <a href="https://register.tokeet.com/" className="try-free-btn">
                                <span>Try for Free</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>


                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                            <h1>
                                Easily access Automata  <br className="d-none d-lg-block" />
                                through Tokeet
                            </h1>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Stay Informed</h4>
                                        <p>
                                            The bookings page lets you see your latest bookings, and 
                                            the latest triggers that have fired for them, at a quick glance.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Complete Control</h4>
                                        <p>
                                            Track your guest's journey with a detailed trigger workflow that 
                                            lets you easily skip specific triggers for each booking.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Extreme Detail</h4>
                                        <p>
                                            View not only which triggers have fired, but the content of those sent messages, as well. 
                                            Know exactly what your guest knows, when they know it.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <a href="https://register.tokeet.com/" className="try-free-btn">
                                <span>Try for Free</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/automata_part_of_tokeet.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 img-holder order-last order-md-first">
                            <img src={require('../../assets/images/solutions/more_then_messaging.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 content">
                            <h1>
                                Stay well informed
                            </h1>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Stay Alert</h4>
                                        <p>
                                            Always know what the last messages sent to your guests were, 
                                            so that you know up-to-the-minute everything that your guest knows.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Monitor Activity</h4>
                                        <p>
                                            Easy-to-read graphs give you all the information you need about 
                                            what types of messages have been sent recently.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Recent Events</h4>
                                        <p>
                                            Your activity feed will keep you instantly informed of all of your guest's recent activities.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <a href="https://register.tokeet.com/" className="try-free-btn">
                                <span>Try for Free</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
        </section>
        </>
    );
}

export default Automation;