import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";
import { Link } from 'react-router-dom';
import $ from 'jquery';

import '../../assets/scss/integrations.scss';

function Integrations(){
    PageTitle('Tokeet | Vacation Rental Management Software Integrations');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('integrations-page')
        return () => {
          document.body.classList.remove('integrations-page')
        }        
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);    
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    // Custom JS for filter and tabs 
    $(document).ready(function(){   
        $("#myInput").on("keyup", function() {
            var value = $(this).val().toLowerCase();
            // eslint-disable-next-line
            $("#integrations-box .box-holder, .box-title-holder").filter(function() {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
            });
        });        
        $("#all").click(function(){
            $(".api-channels, .smart-pricing, .box-title-holder, .guest-msg, .payment-processors, .other-bns-tools, .search-box-holder").slideDown();
        });        
        $("#api-channels").click(function(){
            $(".api-channels").show('slide');
            $(".box-title-holder, .search-box-holder").slideUp();
            $(".smart-pricing, .guest-msg, .payment-processors, .other-bns-tools").hide("slide");
        });
        $("#smart-pricing").click(function(){
            $(".smart-pricing").show('slide');
            $(".box-title-holder, .search-box-holder").slideUp();
            $(".api-channels, .guest-msg, .payment-processors, .other-bns-tools").hide("slide");
        });
        $("#guest-msg").click(function(){
            $(".guest-msg").show('slide');
            $(".box-title-holder, .search-box-holder").slideUp();
            $(".api-channels, .smart-pricing, .payment-processors, .other-bns-tools").hide("slide");
        });
        $("#payment-processors").click(function(){
            $(".payment-processors").show('slide');
            $(".box-title-holder, .search-box-holder").slideUp();
            $(".api-channels, .smart-pricing, .guest-msg, .other-bns-tools").hide("slide");
        });
        $("#other-bns-tools").click(function(){
            $(".other-bns-tools").show('slide');
            $(".box-title-holder, .search-box-holder").slideUp();
            $(".api-channels, .smart-pricing, .guest-msg, .payment-processors").hide("slide");
        });
    });
    
    return(
        <>
            <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-5 banner-caption">
                            <h1>
                                Integrations
                            </h1>
                            <p>
                                Continue using the products you use to help manage your vacation rental business. We are constantly adding new and exciting integrations to Tokeet’s list of connections. We’ll love your feedback on any others you’ll like to be added.
                            </p>
                            <p className="btn-holder">
                                <Link to="/become-a-partner" className="btn btn-become-partner">
                                    Become a Partner
                                </Link>
                            </p>
                        </div>
                        <div className="col-md-7 banner-holder order-first order-md-last">
                            <img src={require('../../assets/images/integrations-banner.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="integrations-holder">
                <div className="container">

                    <div className="nav nav-tabs" role="tablist">
                        <a className="nav-link active" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="true" id="all">All</a>
                        <a className="nav-link" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="false" id="api-channels">API Channels</a>
                        <a className="nav-link" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="false" id="smart-pricing">Smart Pricing</a>
                        <a className="nav-link" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="false" id="guest-msg">Guest Messaging</a>
                        <a className="nav-link" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="false" id="payment-processors">Payment Processors</a>
                        <a className="nav-link" data-bs-toggle="tab" href="/#" role="tab" aria-controls="" aria-selected="false" id="other-bns-tools">Other Business Tools</a>
                    </div>
                    <div className="search-box-holder">
                        <div className="search-box">
                            <i className="bi bi-search"></i>
                            <input id="myInput" type="text" className="form-control" placeholder="Search" />
                        </div>
                    </div>

                    <div className="row box-holder-row" id="integrations-box">
                        {/* API Channels */}
                        <div className="col-12 box-title-holder">
                            <h2>API Channels</h2>
                        </div>
                        {/* Airbnb box */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/airbnb.png')} alt="Tokeet integrates with Airbnb" />
                                    </div>
                                    <div className="content">
                                        <h4>Airbnb</h4>
                                        <p>
                                            Unforgettable trips start with Airbnb. Find adventures nearby or in faraway places and access unique homes, experiences, and places around the world.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Booking.com */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/booking.png')} alt="Tokeet integrates with Booking.com" />
                                    </div>
                                    <div className="content">
                                        <h4>Booking.com</h4>
                                        <p>
                                            Booking.com is a travel fare aggregator website and travel metasearch engine for lodging reservations. It is owned and operated by and is the primary revenue source of USA Booking Holdings. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Expedia */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/expedia.png')} alt="Tokeet integrates with Expedia" />
                                    </div>
                                    <div className="content">
                                        <h4>Expedia</h4>
                                        <p>
                                            Plan your next trip with Expedia. Read reviews on thousands of hotels. Buy airline tickets easily, and bundle for even more savings. Expedia Price Guarantee!
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Flipkey */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/flipkey.png')} alt="Tokeet integrates with Flipkey" />
                                    </div>
                                    <div className="content">
                                        <h4>Flipkey</h4>
                                        <p>
                                            Find amazing deals, authentic guest reviews, and a comprehensive selection of more than 300000 of active properties vacation rentals around the world.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* HomeToGo */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/hometogo.png')} alt="Tokeet integrates with HomeToGo" />
                                    </div>
                                    <div className="content">
                                        <h4>HomeToGo</h4>
                                        <p>
                                            HomeToGo is the search engine where you can plan your vacation quick and easy with the world's largest collection of vacation rentals.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Agoda */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/agoda.png')} alt="Tokeet integrates with Agoda" />
                                    </div>
                                    <div className="content">
                                        <h4>Agoda</h4>
                                        <p>
                                            Agoda has already formed deals and negotiations with their partner hotels which is why they can still offer customers good deals on rooms and packages.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Bookabach */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/bookaback.png')} alt="Tokeet integrates with Bookabach" />
                                    </div>
                                    <div className="content">
                                        <h4>Bookabach</h4>
                                        <p>
                                            Bookabach connects homeowners with over 2 million rental properties to travellers looking for holiday accommodation. Find any rental type you can think of.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Stayz */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/stayz.png')} alt="Tokeet integrates with Stayz" />
                                    </div>
                                    <div className="content">
                                        <h4>Stayz</h4>
                                        <p>
                                            Find the perfect holiday home for your family. Your Together Awaits. SEARCH from over 2 million unique places to stay in 190+ countries.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Abritel */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/abritel.png')} alt="Tokeet integrates with Abritel" />
                                    </div>
                                    <div className="content">
                                        <h4>Abritel</h4>
                                        <p>
                                            Abritel is the French HomeAway booking portal. Today HomeAway operates across 50 international websites including VRBO, HomeAway.co.uk, VacationRentals.com
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* VRBO */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/vrbo.png')} alt="Tokeet integrates with VRBO" />
                                    </div>
                                    <div className="content">
                                        <h4>VRBO</h4>
                                        <p>
                                            Book amazing rentals on Vrbo - the most popular vacation rental site in the US. 
                                        </p>
                                        <ul>
                                            <li>+2 million rentals worldwide</li>
                                            <li>19+ million reviews</li>
                                            <li>Secure online payment</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Travelocity */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/travelocity.png')} alt="Tokeet integrates with Travelocity" />
                                    </div>
                                    <div className="content">
                                        <h4>Travelocity</h4>
                                        <p>
                                            Wander Wisely with the Price Match Guarantee, Free Changes & Cancellations. Book & Save on Packages, Hotels, Flights, Cars, Cruises & more Today! 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Hotels.com */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/hotels-com.png')} alt="Tokeet integrates with Hotels.com" />
                                    </div>
                                    <div className="content">
                                        <h4>Hotels.com</h4>
                                        <p>
                                            From Luxury Hotels to Budget Accommodations, Hotels.com India has the best deals and discounts for hotel rooms anywhere.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* ebookers */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/ebookers.png')} alt="Tokeet integrates with ebookers" />
                                    </div>
                                    <div className="content">
                                        <h4>ebookers</h4>
                                        <p>
                                            Free cancellation on most hotels. Because flexibility matters. Find cheap flight tickets, deals on Hotel bookings, Holiday Car Rentals & Hire.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Orbitz */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/orbitz.png')} alt="Tokeet integrates with Orbitz" />
                                    </div>
                                    <div className="content">
                                        <h4>Orbitz</h4>
                                        <p>
                                            Orbitz.com is a travel fare aggregator website and travel metasearch engine. The website is owned by Orbitz Worldwide, Inc., a subsidiary of Expedia Group.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Egencia */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/egencia.png')} alt="Tokeet integrates with Egencia" />
                                    </div>
                                    <div className="content">
                                        <h4>Egencia</h4>
                                        <p>
                                            The world's business travel platform · Companies of all sizes in more than 60 countries trust Egencia to modernize their corporate travel programs 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Wotif */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/wotif.png')} alt="Tokeet integrates with Wotif" />
                                    </div>
                                    <div className="content">
                                        <h4>Wotif</h4>
                                        <p>
                                            Wotif is a website that provides a reservation service for hotels in Australia and international destinations across the globe. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Trivago */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/trivago.png')} alt="Tokeet integrates with Trivago" />
                                    </div>
                                    <div className="content">
                                        <h4>Trivago</h4>
                                        <p>
                                            Trivago claims to be the world's largest online hotel search site, comparing rates from over 1 million hotels and more than 250 booking sites worldwide. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Expedia group partner solutions */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/expedia-group-partner.png')} alt="Tokeet integrates with Expedia group partner solutions" />
                                    </div>
                                    <div className="content">
                                        <h4>Expedia group partner solutions</h4>
                                        <p>
                                            Expedia Partner Solutions is Expedia Group's B2B brand that unlocks the power of  Expedia Group to support partners' growth through cutting-edge technology
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* KAYAK */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/kayak.png')} alt="Tokeet integrates with KAYAK" />
                                    </div>
                                    <div className="content">
                                        <h4>KAYAK</h4>
                                        <p>
                                            KAYAK searches hundreds of other travel sites at once to find the information you need to make the right decisions on flights, hotels & rental cars.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Expedia group media solutions */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/expedia-media-solutions.png')} alt="Tokeet integrates with Expedia group media solutions" />
                                    </div>
                                    <div className="content">
                                        <h4>Expedia group media solutions</h4>
                                        <p>
                                            A trusted partner for two decades, Expedia Group Media Solutions offers marketers access to the world's best travel advertising platform. Get started today.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Marriott */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/marriott-icon.png')} alt="Tokeet integrates with Marriott" />
                                    </div>
                                    <div className="content">
                                        <h4>Marriott</h4>
                                        <p>
                                            Homes & Villas by Marriott International is a global home rental offering launched in 2019 by Marriott International with a vision to bring Marriott’s 90+ years of hospitality expertise to the home rental market.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Holidu */}
                        <div className="col-md-6 col-lg-4 box-holder api-channels">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/holidu.png')} alt="Tokeet integrates with Holidu" />
                                    </div>
                                    <div className="content">
                                        <h4>Holidu</h4>
                                        <p>
                                            Holidu is a search engine for vacation rentals. Search and compare more than 15 million vacation homes and save up to 55% on identical accommodations.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 box-title-holder">
                            <h2>Smart Pricing</h2>
                        </div>
                        {/* Rategenie */}
                        <div className="col-md-6 col-lg-4 box-holder smart-pricing">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/rategenie.png')} alt="Tokeet integrates with Rategenie" />
                                    </div>
                                    <div className="content">
                                        <h4>Rategenie</h4>
                                        <p>
                                            Automatically update your rates daily with the perfect price across multiple channels. Rategenie's data driven algorithms will help you maximize your profits. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Pricelabs */}
                        <div className="col-md-6 col-lg-4 box-holder smart-pricing">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/pricelabs.png')} alt="Tokeet integrates with Pricelabs" />
                                    </div>
                                    <div className="content">
                                        <h4>Pricelabs</h4>
                                        <p>
                                            PriceLabs is an innovative and easy to use revenue management solution for vacation and short term rentals. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Wheelhouse */}
                        <div className="col-md-6 col-lg-4 box-holder smart-pricing">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/wheelhouse.png')} alt="Tokeet integrates with Wheelhouse" />
                                    </div>
                                    <div className="content">
                                        <h4>Wheelhouse</h4>
                                        <p>
                                            Wheelhouse helps you manage your short-term rentals as professionally and efficiently as major hotels—from understanding performance to better nightly pricing.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 box-title-holder">
                            <h2>Guest Messaging</h2>
                        </div>
                        {/* Automata */}
                        <div className="col-md-6 col-lg-4 box-holder guest-msg">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/automata.png')} alt="Tokeet integrates with Automata" />
                                    </div>
                                    <div className="content">
                                        <h4>Automata</h4>
                                        <p>
                                            Keep your guests and staff informed and empowered from inquiry to checkout with automated communication.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* YourWelcome */}
                        <div className="col-md-6 col-lg-4 box-holder guest-msg">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/yourwelcome.png')} alt="Tokeet integrates with YourWelcome" />
                                    </div>
                                    <div className="content">
                                        <h4>YourWelcome</h4>
                                        <p>
                                            Use YourWelcome tablets to transact and interact with your guests during their stay. Improve their guest experience and increase your revenue at once. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Duve */}
                        <div className="col-md-6 col-lg-4 box-holder guest-msg">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/duve.png')} alt="Tokeet integrates with Duve" />
                                    </div>
                                    <div className="content">
                                        <h4>Duve</h4>
                                        <p>
                                            Duve is an end-to-end guest management system helping hoteliers achieve a personalized and contactless guest experience & upsell services.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Twilio */}
                        <div className="col-md-6 col-lg-4 box-holder guest-msg">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/twilio.png')} alt="Tokeet integrates with Twilio" />
                                    </div>
                                    <div className="content">
                                        <h4>Twilio</h4>
                                        <p>
                                            Twilio strengthens your customer relationships by uniting communications across your entire business, from marketing and sales to customer service and operations.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Charge Automation */}
                        <div className="col-md-6 col-lg-4 box-holder guest-msg">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/charge-automation.png')} alt="Tokeet integrates with Charge Automation" />
                                    </div>
                                    <div className="content">
                                        <h4>Charge Automation</h4>
                                        <p>
                                            Globally recognized leader in Online Check-in, Smart Payment Collection, Security Deposit, Upsell & Chargeback Protection for Hotels & Vacation Homes.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 box-title-holder">
                            <h2>Payment Processors</h2>
                        </div>
                        {/* Stripe */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/stripe.png')} alt="Tokeet integrates with Stripe" />
                                    </div>
                                    <div className="content">
                                        <h4>Stripe</h4>
                                        <p>
                                            Stripe is the best software platform for running an internet business. We handle billions of dollars every year for foward-thinking businesses around the world. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* PayPal */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/paypal.png')} alt="Tokeet integrates with PayPal" />
                                    </div>
                                    <div className="content">
                                        <h4>PayPal</h4>
                                        <p>
                                            PayPal is the faster, safer way to send money, make an online payment, receive money or set up a merchant account. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Authorize.Net */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/authorize-net.png')} alt="Tokeet integrates with Authorize.Net" />
                                    </div>
                                    <div className="content">
                                        <h4>Authorize.Net</h4>
                                        <p>
                                            Authorize.net supports payment processing by helping small businesses accept credit card and eCheck payments online, in person, via mobile devices.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Worldpay */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/worldpay.png')} alt="Tokeet integrates with Worldpay" />
                                    </div>
                                    <div className="content">
                                        <h4>Worldpay</h4>
                                        <p>
                                            Worldpay Is a Merchant Services and Payment Processing Provider Offering a Payment Gateway for Online Transactions.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* PAYMILL */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/paymill.png')} alt="Tokeet integrates with PAYMILL" />
                                    </div>
                                    <div className="content">
                                        <h4>PAYMILL</h4>
                                        <p>
                                            PAYMILL is a payment solution platform that enables online businesses to accept payments on their websites within a very short period of time.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BlueSnap */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/bluesnap.png')} alt="Tokeet integrates with BlueSnap" />
                                    </div>
                                    <div className="content">
                                        <h4>BlueSnap</h4>
                                        <p>
                                            BlueSnap All-in-One Payment Platform allows businesses to easily accept online and mobile payments across the world.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Paysafe */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/paysafe.png')} alt="Tokeet integrates with Paysafe" />
                                    </div>
                                    <div className="content">
                                        <h4>Paysafe</h4>
                                        <p>
                                            Paysafe is a prepaid payment method that allows customers to make payments online without the use of a bank account or credit card information.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* MercadoPago */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/mercadopago.png')} alt="Tokeet integrates with MercadoPago" />
                                    </div>
                                    <div className="content">
                                        <h4>MercadoPago</h4>
                                        <p>
                                            Mercado Pago's Official Magento 2 Plugin. Contribute to mercadopago/cart-magento2 development by creating an account on GitHub.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Braintree */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/braintree.png')} alt="Tokeet integrates with Braintree" />
                                    </div>
                                    <div className="content">
                                        <h4>Braintree</h4>
                                        <p>
                                            Braintree's payment platform provides all the tools online and mobile businesses need to accept payments. Acquired by PayPal.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* PayPal Commerce Platform */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/paypal.png')} alt="Tokeet integrates with PayPal Commerce Platform" />
                                    </div>
                                    <div className="content">
                                        <h4>PayPal Commerce Platform</h4>
                                        <p>
                                            PayPal Commerce Platform is a full-stack solution that offers marketplaces and other commerce platforms powerful and flexible payment processing.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Wepay */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/wepay.png')} alt="Tokeet integrates with Wepay" />
                                    </div>
                                    <div className="content">
                                        <h4>Wepay</h4>
                                        <p>
                                            WePay is an online payment service company that provides secure and comprehensive APIs designed for ISVs and SaaS providers.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Google Pay */}
                        <div className="col-md-6 col-lg-4 box-holder payment-processors">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/google-pay.png')} alt="Tokeet integrates with Google Pay" />
                                    </div>
                                    <div className="content">
                                        <h4>Google Pay</h4>
                                        <p>
                                            Google Pay is a digital wallet platform and online payment system developed by Google to power in-app, online, and in-person contactless purchases on mobile devices, enabling users to make payments with Android phones, tablets, or watches.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 box-title-holder">
                            <h2>Other Business Tools</h2>
                        </div>
                        {/* Signature */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/signature.png')} alt="Tokeet integrates with Signature" />
                                    </div>
                                    <div className="content">
                                        <h4>Signature</h4>
                                        <p>
                                            Signature is the fastest way to get customized, secure, and legally binding signatures from your guests on your most important short-term rental agreements.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Owner Center */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/ownercenter.png')} alt="Tokeet integrates with Owner Center" />
                                    </div>
                                    <div className="content">
                                        <h4>Owner Center</h4>
                                        <p>
                                            Simple Owner Management - create owner invoices, statements, booking reports and collect payments.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Margins */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/margin.png')} alt="Tokeet integrates with Margins" />
                                    </div>
                                    <div className="content">
                                        <h4>Margins</h4>
                                        <p>
                                            Simple and Smart Reporting to help grow your business including stats on invoices, inquiries, tasks, and expenses.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Webready */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/webready.png')} alt="Tokeet integrates with Webready" />
                                    </div>
                                    <div className="content">
                                        <h4>Webready</h4>
                                        <p>
                                            Webready allows you to create your own high-quality website exactly the way you want, whether it’s your first time creating a site or you’re a long time pro.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Checklist */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/checklist.png')} alt="Tokeet integrates with Checklist" />
                                    </div>
                                    <div className="content">
                                        <h4>Checklist</h4>
                                        <p>
                                            Checklist is the staff management app of your dreams. Get your cleaning and other tasks done right with Checklist.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Mailgun */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/mailgun.png')} alt="Tokeet integrates with Mailgun" />
                                    </div>
                                    <div className="content">
                                        <h4>Mailgun</h4>
                                        <p>
                                            Mailgun is the all-in-one, intelligent email delivery platform trusted by businesses, loved by developers.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Mailchimp */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/mailchimp.png')} alt="Tokeet integrates with Mailchimp" />
                                    </div>
                                    <div className="content">
                                        <h4>Mailchimp</h4>
                                        <p>
                                            Mailchimp is an always-on marketing platform for small businesses. We make innovative, beautiful products that empower those businesses to find more customers. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Guestflow */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/guest-flow.png')} alt="Tokeet integrates with Guestflow" />
                                    </div>
                                    <div className="content">
                                        <h4>Guestflow</h4>
                                        <p>
                                            Guestflow helps teams spend less time managing guests.  It combines a fully featured guest app - including automated payments, deposits, verification and access details - with a powerful CRM and team collaboration tool to assign and manage tasks within your business.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Dropbox */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/dropbox.png')} alt="Tokeet integrates with Dropbox" />
                                    </div>
                                    <div className="content">
                                        <h4>Dropbox</h4>
                                        <p>
                                            Dropbox is a modern workspace designed to reduce busywork—so you can focus on the things that matter.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Google Analytics */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/google-analytics.png')} alt="Tokeet integrates with Google Analytics" />
                                    </div>
                                    <div className="content">
                                        <h4>Google Analytics</h4>
                                        <p>
                                            Google Analytics lets you measure your advertising ROI as well as track your Flash, video, and social networking sites and applications.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Slack */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/slack.png')} alt="Tokeet integrates with Slack" />
                                    </div>
                                    <div className="content">
                                        <h4>Slack</h4>
                                        <p>
                                            Slack is a collaboration hub for work, no matter what work you do. It’s a place where conversations happen, decisions are made, and information is always at your fingertips. 
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Operto */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/operto.png')} alt="Tokeet integrates with Operto" />
                                    </div>
                                    <div className="content">
                                        <h4>Operto</h4>
                                        <p>
                                            Intelligent Control of Smart Home Devices for Vacation Rentals and Hotels. Boost Revenue & Save Time with Keyless Guest Entry and In-Suite Technology.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* SUPERHOG */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/superhog.png')} alt="Tokeet integrates with SUPERHOG" />
                                    </div>
                                    <div className="content">
                                        <h4>SUPERHOG</h4>
                                        <p>
                                            Intelligent guest screening with automatic damage protection that integrates seamlessly with an unmatched $5,000,000 Host Guarantee.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* AirDNA */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/airdna.png')} alt="Tokeet integrates with AirDNA" />
                                    </div>
                                    <div className="content">
                                        <h4>AirDNA</h4>
                                        <p>
                                            AirDNA, LLC provides software solutions. The Company offers a platform that turns short-term vacation rental data into actionable analytics.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* TurnoverBnB */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/turnoverbnb.png')} alt="Tokeet integrates with TurnoverBnB" />
                                    </div>
                                    <div className="content">
                                        <h4>TurnoverBnB</h4>
                                        <p>
                                            TurnoverBnB is a platform that helps vacation rental property managers automatically schedule and manage their turnovers via our website and mobile app.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Boostly */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/boostly.png')} alt="Tokeet integrates with Boostly" />
                                    </div>
                                    <div className="content">
                                        <h4>Boostly</h4>
                                        <p>
                                            Boostly focuses on custom built Wordpress websites that can handle the growth of your business by directly connecting.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Operto Teams */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/operto.png')} alt="Tokeet integrates with Operto Teams" />
                                    </div>
                                    <div className="content">
                                        <h4>Operto Teams</h4>
                                        <p>
                                            Never miss another clean, inspection or any other task and save hours in scheduling, billing, and payroll. Are you ready to move from chaos to calm? Book an Operto Teams demo today!
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* DOINN */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/doinn.png')} alt="Tokeet integrates with DOINN" />
                                    </div>
                                    <div className="content">
                                        <h4>DOINN</h4>
                                        <p>
                                            Doinn gets your properties ready for the next guests without all the work. A technology bridge between property rental managers and quality local service providers.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* InsuraGuest */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/insura-guest.png')} alt="Tokeet integrates with InsuraGuest" />
                                    </div>
                                    <div className="content">
                                        <h4>InsuraGuest</h4>
                                        <p>
                                            Protect your vacation rental property and guests with the best short-term rental insurance on the market.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Minut */}
                        <div className="col-md-6 col-lg-4 box-holder other-bns-tools">
                            <div className="box">
                                <div className="content-holder">
                                    <div className="flex-box">
                                    <div className="img-holder">
                                        <img src={require('../../assets/images/integrations/minut.png')} alt="Tokeet integrates with Minut" />
                                    </div>
                                    <div className="content">
                                        <h4>Minut</h4>
                                        <p>
                                            Keep your home safe and your neighbors happy while respecting your guests' privacy. Only Minut gives you the full insight you need to protect your homes.
                                        </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );    
}

export default Integrations;