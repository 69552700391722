import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/solutions.scss';

function InvoicePayments(){
    PageTitle('Tokeet | Invoicing & Payments for Short-Term Rental Managers');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('solutions-template')
        return () => {
          document.body.classList.remove('solutions-template')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-md-6 banner-caption">
                        <h1>
                            Invoicing
                        </h1>
                        <p>
                            Create & send invoices with a click. Easily accept credit card payments online.
                        </p>
                        <p className="btn-holder">
                            <a href="https://register.tokeet.com/" className="btn btn-start-trial">
                                Start Free Trial
                            </a>
                            <a href="https://www.youtube.com/embed/EMSZsWg54HI" data-lity className="btn watch-video-btn">
                                <span>Watch Video</span>
                                <i className="bi bi-play-circle-fill"></i>
                            </a>
                        </p>
                        <img src={require('../../assets/images/solutions/payment-partner.png')} className="img-fluid" alt="" />
                    </div>
                    <div className="col-md-6 banner-holder order-first order-md-last">
                        <img src={require('../../assets/images/invoice-payments-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="temp-content-holder">
            <div className="container">
                <div className="dark-box-top">
                    <div className="title-holder">            
                        <h4>EASY PAYMENTS</h4>            
                        <h1>
                            Invoicing and collecting payments from <br className="d-none d-lg-block" />
                            your guests has never been simpler.
                        </h1>
                    </div>
                    <div className="row justify-content-between">
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/no_middle_men.png')} alt="" />
                            </div>
                            <h4>
                                No Middle Men
                            </h4>
                            <p>
                                Get payments from guests sent directly to your account and not through Tokeet. No more waiting for settlement to receive your payment. Tokeet uses your own Stripe and Paypal accounts to collect 
                                payment from guests on your behalf. Our secure and robust API integrations make it all possible.
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/automate_invoices.png')} alt="" />
                            </div>
                            <h4>Schedule & Automate <br /> Your Invoices</h4>
                            <p>
                                Never forget to send an invoice again. Schedule your invoices to be sent at any time in the future.  Receive notifications when an invoice is due, past due, or paid. Automatically send payment reminders to guests (x) days before an invoice is due or a thank you message after an invoice is paid. 
                                With Tokeet the automation possibilities are endless. 
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 box">
                            <div className="icon-holder">
                                <img src={require('../../assets/images/solutions/credit_cards.png')} alt="" />
                            </div>
                            <h4>Accept Major <br /> Credit Cards</h4>
                            <p>
                                You can charge almost any kind of credit or debit card, with Stripe. U.S. businesses can accept Visa, MasterCard, American Express, JCB, Discover, and Diners Club. Australian, Canadian, European, Hong Kongese, Japanese, 
                                and Singaporean businesses can accept Visa, MasterCard, and American Express.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                            <h1>
                                Start Invoicing<br className="d-none d-lg-block" />
                                In Minutes
                            </h1>
                            <p>Setup takes less than a minute.</p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Quick Payments</h4>
                                        <p>Start receiving payment from guests immediately after completing the fast setup.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Multiple Gateways</h4>
                                        <p>
                                            Connect multiple payment gateways at once, even multiple accounts from the same gateway.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Flexibility</h4>
                                        <p>Turn off online payment for any individual invoice at any time. Choose between Stripe, Paypal, or give the guest both options.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/start_invoicing.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 img-holder order-last order-md-first">
                            <img src={require('../../assets/images/solutions/custom_branding.png')} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-6 content">
                            <h1>
                                Custom Branding
                            </h1>
                            <p>Put your brand front and center with the Custom Branding options in Tokeet.</p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Added Professionalism</h4>
                                        <p>Leave a lasting impression on all your guests by branding your invoices with your logo and business colours.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Easy Customization</h4>
                                        <p>Personalize the text within your invoices and make use of data dictionaries to add values such as rental and guest name.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>No guesswork</h4>
                                        <p>Guests will receive your customized email notification with a link to complete payment.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="content-box">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-md-6 content">
                            <h1>
                                Powerful Rules To Control <br className="d-none d-lg-block" />
                                Payments
                            </h1>
                            <p>
                                Enjoy flexibility of our payment rules and specify which gateway is used for 
                                which channel or rental.
                            </p>
                            <ul>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexibility.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Gateway management</h4>
                                        <p>Our payment rules automatically select gateways as needed based on the channel or rental allowing you to connect to multiple gateways.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/flexible_pricing.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Default option</h4>
                                        <p>Set a gateway as the default one to automatically use that gateway in the absence of rules or when there is none matching that rental or channel.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon-holder">
                                        <img src={require('../../assets/images/solutions/innovative.png')} alt="" />
                                    </div>
                                    <div className="li-content">
                                        <h4>Priority Setup</h4>
                                        <p>With the use of multiple payment rules catering to different channels and rentals, a priority order is used to determine which rule to use in different scenarios.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 img-holder">
                            <img src={require('../../assets/images/solutions/payments.png')} className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
}

export default InvoicePayments;