import { React, useEffect, useState } from "react";
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";

import '../../assets/scss/privacy.scss';

function BecomeApartnerFormSuccess(){
    PageTitle('Tokeet | Become a Partner');
    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.classList.add('become-a-partner-page')
        return () => {
          document.body.classList.remove('become-a-partner-page')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    return(
        <>
        <section className="top-banner">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-md-6 banner-caption text-center">
                            <h1>
                                Thank you! 
                            </h1>
                            <p>
                                Your form has been submitted. Someone from Partnerships department will get in touch with you.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default BecomeApartnerFormSuccess;