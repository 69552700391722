import { React, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { PageTitle } from "../components/PageTitles";
import ClockLoader  from "react-spinners/ClockLoader";
import $ from 'jquery';

import '../../assets/scss/affiliate.scss';

function Affiliate(){
    PageTitle('Tokeet | Vacation Rental Owner Management Tools');
    useEffect(() => {        
        window.scrollTo(0, 0);
        document.body.classList.add('affiliate-layout')
        return () => {
          document.body.classList.remove('affiliate-layout')
        }
    }, [])
    const [isFetching, setIsFetching] = useState(true); 
    useEffect(() => {
        setTimeout(function () {
        setIsFetching(false); 
        }, 500);
    }, []);
    if (isFetching) {
        return (        
            <div className="loading-screen">
                <ClockLoader color="#1AA99D" size={50} />
            </div>
        );
    }
    $(document).ready(function () {
        var rentalCountSlider = $('.affiliate-slider');
        if (!rentalCountSlider.length) {
            return;
        }
        var DEFAULT_RENTAL_COUNT = 0;
        rentalCountSlider.val(DEFAULT_RENTAL_COUNT);
        rentalCountSlider.change(function () {
            var rentalCount = $(this).val();
            var val = (rentalCount - $(this).attr('min')) / ($(this).attr('max') - $(this).attr('min'));
    
            $(this).css('background-image',
                '-webkit-gradient(linear, left top, right top, '
                + 'color-stop(' + val + ', #17A52F), '
                + 'color-stop(' + val + ', #EDEDED)'
                + ')'
            );
        });
    
        var slider = document.getElementById("myCommission");
        var output = document.getElementById("account");
        var price = document.getElementById("totalCommission");
        output.innerHTML = slider.value;
    
        slider.oninput = function() {
          output.innerHTML = this.value;
          price.innerHTML = Math.round(this.value * 16.00).toFixed(2);
        }
    });
    return(
        <>
        <section className="top-banner">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-7 banner-caption">
                        <h1>
                            Make Money While <br />
                            Sharing Tokeet
                        </h1>
                        <p>
                            Do you love sharing things online? Do you want to help property managers grow? As a 
                            Tokeet affiliate you will earn a recurring 20% commission for every new paid Tokeet Subscription you send our way.
                        </p>
                        <p className="btn-holder">
                            <a href="https://affiliates.tokeet.com/signup" className="btn btn-start-trial">
                                Join Tokeet Affiliate Program
                            </a>
                            <a href="https://youtu.be/6y-7E--UwFQ" data-lity className="btn watch-video-btn">
                                <span>Watch Video</span>
                                <i className="bi bi-play-circle-fill"></i>
                            </a>
                        </p>
                    </div>
                    <div className="col-md-5 col-lg-4 banner-holder">
                        <img src={require('../../assets/images/affiliate-banner.png')} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
        <section className="content-holder">
            <div className="container">
                <div className="container-box">
                    <div className="title-holder">
                        <h1>
                            Here’s how the Tokeet<br />
                            Affiliate Program works
                        </h1>
                    </div>
                    <div className="row step-holder">
                        <div className="col-md-6 step step1">
                            <div className="label-holder">
                                <label><span>1</span>Sign Up</label>
                            </div>
                            <div className="step-content">
                                <p>
                                    Anyone can join the Tokeet affiliate program. Seriously, you don’t even need to be a Tokeet customer to promote Tokeet. But to be a successful affiliate, you should:
                                </p>
                                <ol>
                                    <li>Understand the short-term rental industry and its needs.</li>
                                    <li>Have an audience who needs a powerful, yet simple property management tool to help grow their business.</li>
                                    <li>Promote and influence your audience to use the Tokeet platform. We'll help you along the way.</li>
                                </ol>
                            </div>
                        </div>
                        <div className="col-md-6 step">
                            <div className="step-content testimonial-holder">
                                <div id="testimonial" className="carousel testimonial slide carousel-fade" data-bs-ride="carousel">
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <div className="content">
                                                These guys run their business as true professionals and build state-of-the-art, well-designed, modern software for both web and mobile. Tokeet provided me the best-in-class solution to help me manage my properties on Airbnb and take the next step to scale the listings and drive more 
                                                business through other channels. <Link to="/tokeet-reviews">Read more</Link>
                                            </div>
                                            <div className="info">
                                                <h4>L. Broward</h4>
                                                <p><img src={require('../../assets/images/icons/sourceforge.png')} height="18" alt="" /></p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="content">
                                                I've been using them for a year now and they've been awesome. There's a little learning curve since their features are more advanced than the other vacation rental management programs I tried, but that was over in a couple days. They walked me through
                                                everything and were very kind about it, so I have to give them a lot of credit there too. <Link to="/tokeet-reviews">Read more</Link>
                                            </div>
                                            <div className="info">
                                                <h4>Chris .p</h4>
                                                <p><img src={require('../../assets/images/icons/crowd.png')} height="25" alt="" /></p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="content">
                                                The developers are consistent with updating new features and customer support is very responsive. <Link to="/tokeet-reviews">Read more</Link>
                                            </div>
                                            <div className="info">
                                                <h4>Raj.N</h4>
                                                <p><img src={require('../../assets/images/icons/crowd.png')} height="25" alt="" /></p>
                                            </div>
                                        </div>
                                        <div className="carousel-item">
                                            <div className="content">
                                                By far the best PMS/channel manager out there at the moment. I have been 
                                                trying various systems for months and this is really intuitive and feature packed - it's so simple to use. <Link to="/tokeet-reviews">Read more</Link>
                                            </div>
                                            <div className="info">
                                                <h4>Moshin.c</h4>
                                                <p><img src={require('../../assets/images/icons/google_play.png')} height="30" alt="" /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <a className="carousel-ctrl-prev" href="#testimonial" role="button" data-bs-slide="prev">
                                        <i className="bi bi-chevron-left"></i>
                                    </a>
                                    <a className="carousel-ctrl-next" href="#testimonial" role="button" data-bs-slide="next">
                                        <i className="bi bi-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row step-holder">                    
                        <div className="col-md-6 step order-last order-md-first">
                            <div className="step-content img-holder">
                                <img src={require('../../assets/images/share-with-your-audience.png')} className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-md-6 step step2">
                            <div className="label-holder">
                                <label><span>2</span>Share with your audience</label>
                            </div>
                            <div className="step-content">
                                <h4>Share with your audience</h4>
                                <p>
                                    To earn that sweet 20% recurring commission, all you need to do is start sharing the custom link we send you in all the right places. But we want you to be a really successful affiliate. That’s why we also hook you up with helpful resources including:
                                </p>
                                <ol>
                                    <li>A training course with one of our Tokeet Affiliate Specialists.</li>
                                    <li>Graphics and video assets to create your content.</li>
                                    <li>Tokeet Webinars to use as a funnel.</li>
                                    <li>A dashboard to track your progress.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="row step-holder">
                        <div className="col-md-6 step step3">
                            <div className="label-holder">
                                <label><span>3</span>Earn!</label>
                            </div>
                            <div className="step-content">
                                <p>
                                    We send you a 20% commission for every user who creates a paid Tokeet account through your affiliate link. But it’s not just that one payment when they sign up. That 20% is going to hit your bank account every month for as long as their Tokeet account is active for up to one year. 
                                    <br /><br />
                                    Want to see how much you could be making right now? 
                                    <br /><br />
                                    Slide the toggle on our Commision Calculator to get a look at some real numbers you could be earning. 
                                    <br /><br />
                                    P.S. This scale is for illustration purposes only. You can earn MUCH more if your referrals have larger accounts.
                                </p>
                            </div>
                        </div>                  
                        <div className="col-md-6 step">
                            <div className="step-content">
                                <h3>TOKEET AFFILIATE COMMISSION CALCULATOR</h3>
                                <div className="slidecontainer">
                                    <div className="row plan-price-details">
                                        <div className="col-12">
                                        <h4 className="commission-count">
                                            $<span id="totalCommission">16.00</span><span className="month">/mo</span>
                                        </h4>
                                        <p className="price-detail">
                                            Monthly commission for referring<br />
                                            <strong><span id="account"></span> account</strong> for up to 1,000 subscribers
                                        </p>
                                        </div>
                                    </div>
                                    <input type="range"  min="1" max="600" step="1" className="affiliate-slider" id="myCommission" />
                                </div>
                                <div className="info_box">
                                    Slide to see the commission grow!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>

        <section className="start-earning">
            <div className="container">
                <div className="container-box">
                    <div className="row">
                        <div className="col-lg-6">
                            <h4>TOKEET AFFILIATE</h4>
                            <h1>
                                Start earning as a<br /> 
                                Tokeet affiliate today!
                            </h1>
                        </div>
                        <div className="col-lg-6">
                            <p>
                                Through our Tokeet affiliate program you’re not only earning extra money for you and your family, 
                                you’re also helping others in the Tokeet Community just like you do the same. By connecting
                                your audience with Tokeet you’re helping them grow their business and live their ambitious dreams.
                            </p>
                            <div className="btn-holder">
                                <a href="https://affiliates.tokeet.com/signup" className="btn btn-join">
                                    Join Tokeet Affiliate Program
                                </a>
                                <Link to="/affiliate/faq" className="btn btn-faq">
                                    FAQ
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </section>
        </>
    );
}

export default Affiliate;